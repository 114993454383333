import { useEffect, useRef, useState } from 'react'
import { isInteger } from 'lodash'

import RangeList from './rangeList'
import { Button, Card, Checkbox, DatePicker, Form, Input, InputNumber, Select, Space, message } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import SpecificForm from '../SpecificForm/specificForm'
import { reportTypes } from '../../constants/formConstants'
import BaseSpecificWaypointForm from '../SpecificForm/SpecificWaypointForm/specificWaypointForm'
import { BaseFormContext } from '../../contexts/baseFormContext'
import BaseSpecificOppositeForm from '../SpecificForm/SpecificOppositeForm/specificOppositeForm'
import BaseSpecificNeutralizationForm from '../SpecificForm/SpecificNeutralizationForm/specificNeutralizationForm'
import BaseSpecificSpeedZonesForm from '../SpecificForm/SpecificSpeedZonesForm/specificSpeedZonesForm'
import BaseSpecificMatchForm from '../SpecificForm/SpecificMatchForm/specificMatchForm'
import BaseSpecificCustomZoneForm from '../SpecificForm/SpecificCustomZoneForm/specificCustomZoneForm'
import axios from 'axios'
import BaseSpecificGenericSpeed from '../SpecificForm/SpecificGenericSpeedForm/specificGenericSpeedForm'
import CustomInputDateTime from '../CustomInputDateTime'
import { DateTimeRules } from '../../constants/dateTimeRules'
import moment from 'moment'
dayjs.extend(utc)
dayjs.extend(timezone)
const CATEGORY_ALL = '(All)'
const CLASS_ALL = '(All)'

const NO_TRACK_ID = -1
const baseOptions = [
    { value: 'both', label: 'Both' },
    { value: 'main', label: 'Primary' },
    { value: 'secondary', label: 'Secondary' },
]
interface Props {
    tracks: Tracks[]
    participants: Participant[]
    rallyId: number
    categories: string[]
    classes: string[]
    timezone: string
    onTrackChange: (sectionId: number | null) => void
    onChangeDetailsZoom: (value: boolean) => void
    onSubmit: (participants: Participant[], data: DataRallyForm) => void
    onTypeChange: (value: string) => void
    onCancel: () => void
    resultsState: string
    legendType: ResultData
}
const RallyForm = ({
    categories,
    classes,
    legendType,
    onCancel,
    onChangeDetailsZoom,
    onSubmit,
    onTrackChange,
    onTypeChange,
    participants,
    rallyId,
    resultsState,
    timezone,
    tracks,
}: Props) => {
    const [valueWp, setValueWp] = useState<ValuesWaypoints[]>([{ name: 'No wp', key: '-1' }])
    const [selectedParticipants, setSelectedParticipants] = useState<Participant[]>(participants)

    const [supportedParticipantTrackTypes, setSupportedParticipantTrackTypes] = useState(baseOptions)
    const [detection, setDetection] = useState<string>(reportTypes[0].value)
    const [participantRange, setParticipantRange] = useState<string>('')
    const [filterCategory, setFilterCategory] = useState<string>(CATEGORY_ALL)
    const [filterClass, setFilterClass] = useState<string>(CLASS_ALL)
    const [showConnections, setShowConnections] = useState<boolean>(false)
    const [detailsZoom, setDetailsZoom] = useState<boolean>(false)
    const [ut_ini, setUt_ini] = useState<number>(tracks[0].ut_ini)
    const [ut_end, setUt_end] = useState<number>(tracks[0].ut_fin || tracks[0].ut_ini)
    const [participantTrackType, setParticipantTrackType] = useState<string>('both')
    const [trackId, setTrackId] = useState<number>(NO_TRACK_ID)
    const [workers, setWorkers] = useState<number>(8)
    const [specificData, setSpecificData] = useState()
    const specificFormRef = useRef(null)
    const [specificForm, setSpecificForm] = useState<JSX.Element>(<></>)
    const [criterias, setCriterias] = useState<CriteriaAPI[]>([])
    const [form] = Form.useForm()

    useEffect(() => {
        axios.get('https://rest.anube.es/rallyrest/default/api/overspeed_criterias.json?active=1').then(response => {
            const transformedData = response.data.event.data.map(
                (item: any): CriteriaAPI => ({
                    active: item.active === 1,
                    diff_bonus: Number(item.diff_bonus),
                    money_unit: item.money_unit,
                    description: item.description,
                    overspeed_is_coefficient: item.overspeed_is_coefficient === 1,
                    penalty_is_coefficient: item.penalty_is_coefficient === 1,
                    penalty_over_tolerance: item.penalty_over_tolerance === 1,
                    penalty_unit: item.penalty_unit,
                    regulation_text: item.regulation_text,
                    short_name: item.short_name,
                    table: item.table.map(
                        (tableItem: any): CriteriaTable => ({
                            over_value: Number(tableItem.over_value),
                            sanctions: tableItem.sanctions.map(
                                (sanction: any): Sanctions => ({
                                    penalty: Number(sanction.penalty),
                                    money: Number(sanction.money),
                                })
                            ),
                        })
                    ),
                    whole_zone_as_unique_impulse: item.whole_zone_as_unique_impulse === 1,
                })
            )
            setCriterias(transformedData)
        })
    }, [])

    useEffect(() => {
        setDetection(reportTypes[0].value)
        setParticipantRange('')
        setFilterCategory(CATEGORY_ALL)
        setFilterClass(CLASS_ALL)
        setShowConnections(false)
        setDetailsZoom(false)
        setUt_ini(tracks[0].ut_ini)
        setUt_end(tracks[0].ut_fin || tracks[0].ut_ini)
        setParticipantTrackType('both')
        setTrackId(NO_TRACK_ID)
        setWorkers(8)
        form.setFieldsValue({
            type: reportTypes[0].value,
            section: NO_TRACK_ID.toString(),
            category: CATEGORY_ALL,
            class: CLASS_ALL,
            wrokers: 8,
            connnect: false,
            trackType: 'both',
        })
    }, [rallyId])

    useEffect(() => {
        if (legendType) {
            const waypointsTypes = legendType.analysis
                .find(e => e.type === 'waypoints')
                ?.data.map(e => {
                    return {
                        key: e.id?.toString(),
                        name: e.name,
                        id: e.id,
                    }
                })
            if (waypointsTypes) setValueWp(waypointsTypes)
        }
    }, [legendType])

    const getParticipantsFromRange = (selectExpression: string) => {
        let res: Participant[] = []
        selectExpression = selectExpression.trim()

        if (selectExpression !== '') {
            let numbers: string[] = []
            for (let range of selectExpression.split(/[\s+\t\r\n\.\,]/)) {
                let edges = range.split('-')

                if (edges.length === 1) {
                    numbers.push(edges[0])
                } else if (edges.length === 2) {
                    for (
                        let n = parseInt(edges[0].replace(/[^0-9]/g, ''));
                        n <= parseInt(edges[1].replace(/[^0-9]/g, ''));
                        n++
                    ) {
                        if (isInteger(edges[0])) {
                            numbers.push(n.toString())
                        } else {
                            const letters = edges[0].replace(/[^a-zA-Z]/g, '')
                            numbers.push(letters + n.toString())
                        }
                    }
                } else {
                    continue
                }
            }

            const getItemByNumber = (number: string) => {
                let item = participants?.filter((item: Participant) => {
                    return item.numero === number
                })

                if (item && item.length > 0) {
                    return item[0]
                }
                return undefined
            }

            const isDefined = (item: Participant | undefined): item is Participant => item !== undefined

            const filteredParticipants = numbers
                .map((number: string) => number.trim())
                .map(getItemByNumber)
                .filter(isDefined)

            if (filteredParticipants) {
                res = filteredParticipants
            }

            res = Array.from(new Set(res))
        } else {
            res = participants
        }
        setSelectedParticipants(res)
        return res
    }

    useEffect(() => {
        if (selectedParticipants && form) {
            form.setFieldsValue({ participant: selectedParticipants })
        }
    }, [selectedParticipants])

    useEffect(() => {
        const selectedTrack = tracks.find(e => e.section === trackId)

        const detectionComponents: Record<string, JSX.Element> = {
            matching: <BaseSpecificMatchForm />,
            opposite: <BaseSpecificOppositeForm />,
            waypoints: <BaseSpecificWaypointForm waypoints={valueWp} />,
            speed: <BaseSpecificSpeedZonesForm />,
            'neut-zones': <BaseSpecificNeutralizationForm />,
            'custom-zone': (
                <BaseSpecificCustomZoneForm
                    entryCoords={selectedTrack?.entryCoords}
                    exitCoords={selectedTrack?.exitCoords}
                />
            ),
            genericSpeed: (
                <BaseSpecificGenericSpeed
                    entryCoords={selectedTrack?.entryCoords}
                    exitCoords={selectedTrack?.exitCoords}
                    criterias={criterias}
                />
            ),
        }

        setSpecificForm(detectionComponents[detection])
    }, [detection, valueWp])

    const sectionChanged = (value: string) => {
        try {
            const section = tracks.find((section: Tracks) => section.section === parseInt(value))
            if (section) {
                const ut_end = section.ut_fin || section.ut_ini

                setUt_ini(section.ut_ini)
                setUt_end(ut_end)
            }
        } catch (e) {
            // nothing, exception just to avoid error
            // when the track is '-'
        }

        const trackId = parseInt(value)
        setTrackId(trackId)
        onTrackChange(trackId === NO_TRACK_ID ? null : trackId)
    }

    const _detectionChanged = (detection: string) => {
        if (detection === 'custom-zone') {
            if (participantTrackType === 'both') {
                setParticipantTrackType('main')
                form.setFieldsValue({ trackType: 'main' })
            }
            setSupportedParticipantTrackTypes(baseOptions.filter(e => e.value != 'both'))
        } else {
            setParticipantTrackType('both')
            form.setFieldsValue({ trackType: 'both' })
            setSupportedParticipantTrackTypes(baseOptions)
        }
    }
    useEffect(() => {
        if (ut_end && ut_ini) {
            form.setFieldsValue({
                date: [dayjs.unix(ut_ini).tz(timezone), dayjs.unix(ut_end).tz(timezone)],
            })
        }
    }, [ut_ini, ut_end])

    const validateSection = (rule: any, value: any) => {
        if (value === NO_TRACK_ID.toString()) {
            return Promise.reject(new Error('Invalid selection'))
        } else {
            return Promise.resolve()
        }
    }

    const onFinish = async (values: any) => {
        const isChildFormValid = await (specificFormRef?.current! as any).validate()
        if (!isChildFormValid) {
            message.error(`Form ${detection} is invalid`)
            return
        }
       console.log(values)

        let data: DataRallyForm = {
            track: values.section,
            from: values.dateFrom,
            to: values.dateTo,
            participantTrackType: values.trackType,
            detectionType: values.type,
            workers: values.workers,
            detectionTypeData: specificData,
            showConnections: values.connected,
        }
        onSubmit(values.participant, data)
        console.log('Received values of form:', values)
    }

    const generalFormComponents = [
        <Form.Item
            name="section"
            label="Section"
            className="font-bold "
            rules={[{ message: 'Section is required' }, { validator: validateSection }]}
            initialValue={trackId.toString()}
        >
            <Select
                className="w-full"
                defaultValue={trackId.toString()}
                options={tracks.map((track: Tracks) => ({
                    value: track.section !== undefined ? track.section.toString() : NO_TRACK_ID.toString(),
                    label: track.name,
                }))}
                onChange={sectionChanged}
            />
        </Form.Item>,
        <Form.Item name="participant" label="Participant" className=" font-bold" initialValue={participants}>
            <div>
                <RangeList
                    disabled={false}
                    values={participants?.map((e: Participant) => e.numero)}
                    value={participantRange}
                    onChange={value => {
                        setParticipantRange(value)
                        getParticipantsFromRange(value)
                    }}
                />
            </div>
        </Form.Item>,
        <Form.Item name="category" label="Category" initialValue={CATEGORY_ALL}>
            <Select
                className="w-full"
                onChange={value => setFilterCategory(value)}
                defaultValue={filterCategory}
                options={[CATEGORY_ALL, ...categories].map(e => ({ value: e, label: e }))}
            />
        </Form.Item>,
        <Form.Item name="class" label="Class" initialValue={CLASS_ALL}>
            <Select
                className="w-full"
                onChange={value => setFilterClass(value)}
                defaultValue={filterClass}
                options={[CLASS_ALL, ...classes].map(e => ({ value: e, label: e }))}
            />
        </Form.Item>,
        <Form.Item
            className="w-full"
            name="connect"
            label="Show connected"
            initialValue={showConnections}
            valuePropName="checked"
        >
            <Checkbox className="w-full"></Checkbox>
        </Form.Item>,
        <Form.Item name="dateFrom" label="From" rules={DateTimeRules}>
            <CustomInputDateTime defaultValue={ut_ini} timezone={timezone}></CustomInputDateTime>
        </Form.Item>,
        <Form.Item name="dateTo" label="To" rules={DateTimeRules}>
            <CustomInputDateTime defaultValue={ut_end} timezone={timezone}></CustomInputDateTime>
        </Form.Item>,
        <Form.Item name="type" label="Type" className="font-bold" initialValue={detection}>
            <Select
                className="w-full"
                onChange={value => {
                    setDetection(value)
                    onTypeChange(value)
                    _detectionChanged(value)
                }}
                value={detection}
                options={reportTypes}
            />
        </Form.Item>,
        <Form.Item name="trackType" label="Track type" initialValue={participantTrackType}>
            <Select
                className="w-full"
                defaultValue={participantTrackType}
                options={supportedParticipantTrackTypes}
                onChange={value => {
                    setParticipantTrackType(value)
                }}
            />
        </Form.Item>,
        <Form.Item name="workers" label="Workers" initialValue={workers}>
            <InputNumber
                className="w-full"
                precision={0}
                defaultValue={workers}
                min={1}
                onChange={value => {
                    if (value) setWorkers(value)
                }}
            />
        </Form.Item>,
        <Form.Item label="Details zoom" className="m-0">
            <Checkbox
                onChange={value => {
                    setDetailsZoom(value.target.checked)
                    onChangeDetailsZoom(value.target.checked)
                }}
                value={detailsZoom}
            ></Checkbox>
        </Form.Item>,
    ]

    return (
        <div className="h-full min-w-[200px]">
            <BaseFormContext.Provider value={setSpecificData}>
                <Form form={form} onFinish={onFinish} className="w-full pr-1" disabled={resultsState === 'FETCHING'}>
                    <Card
                        id="generalCardForm"
                        bodyStyle={{ padding: 2 }}
                        className="mt-1 w-full border-solid border-neutral-300 bg-neutral-100 p-2"
                    >
                        {generalFormComponents.map(e => e)}
                    </Card>

                    <SpecificForm ref={specificFormRef} children={specificForm}></SpecificForm>
                    <div className="flex-column flex  space-x-4">
                        <Form.Item className="flex-1 text-center">
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                        <Form.Item className="flex-1 text-center">
                            <Button disabled={resultsState !== 'FETCHING'} onClick={onCancel}>
                                {resultsState === 'CANCELING' ? 'Canceling...' : 'Cancel'}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </BaseFormContext.Provider>
        </div>
    )
}

export default RallyForm

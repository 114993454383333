import ParticipantCard from '../participantCard'
import RangeBar from '../../RangeBar/rangeBar'
import 'moment-timezone'
import './track-card.css'
import PositionsLost from '../../PositionsLost'
import { calculateLostPositions } from '../../../utils'

const TrackCard = ({ className, onClick, result }: any) => {
    let width: number
    let positions = calculateLostPositions(result.disconnectedGpsTrack)
    try {
        width = (result.data as TrackReport).unmatchedChunks[0].total
    } catch (e) {
        width = 1
    }
    const ranges = (result.data as TrackReport).unmatchedChunks.map((unmatch: UnmatchedChunks) => {
        return {
            edges: unmatch.range!,
            disabled: unmatch.disabled || false,
        }
    })
    return (
        <ParticipantCard number={result.number} onClick={() => onClick(result)} className={`${className} track-card`}>
            <RangeBar
                ranges={ranges}
                inactiveStart={(result.data as TrackReport).trackedRelative}
                width={width}
                theme="match-track"
            />
            <PositionsLost positions={positions} />
        </ParticipantCard>
    )
}

export default TrackCard

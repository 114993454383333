import { Card, Collapse, CollapseProps } from 'antd'
import React from 'react'
import './summary_card.css'

interface Props {
    number: string
    onClick: () => void
    className: string
    children?: any
    collapseData?: any
}
const ParticipantCard = ({ className, number, onClick, children, collapseData }: Props) => {
    const selectedValue = className.includes('selected') && collapseData ? 1 : 0

    const collapseItems: CollapseProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className={`${className} summary-card summary-card-content m-1 flex h-full flex-row  rounded border  border-solid border-neutral-300 bg-neutral-100 shadow-sm`}
                    onClick={onClick}
                >
                    <div className="text-md w-11 self-center text-center">#{number}</div>
                    {children}
                </div>
            ),
            children: collapseData,
            showArrow: false,
        },
    ]

    return <Collapse className=" h-full" activeKey={selectedValue} items={collapseItems} bordered={false}></Collapse>
}
export default ParticipantCard

import axios from 'axios';

export function getParticipantTrack(rally : number, participant : number, from : number , to : number , token : string, type_of_track : string) {
    return axios({
        method: 'GET',
        url: 'https://rest.anube.es/rallyrest/default/api/participants_routes.json',
        params: {
            token: token,
            gps: type_of_track,
            ut_ini: from,
            ut_fin: to,
            decimate: 0,
            participants: participant,
            num_fields: 5,
            subrally_id: rally 
        },
    });
}

export function getOrganizerTrack(trackid : number, token : string) {
    return axios({
        method: 'GET',
        url: `https://rest.anube.es/rallyrest/default/api/section_path/${trackid}.json`,
        params: {
            token: token,
            also_hidden: 1,
        },
    });
}

export function getWaypointsForTrack(rallyId : number, trackId : number , token : string) {
    return axios({
        method: 'GET',
        url: `https://rest.anube.es/rallyrest/default/api/waypoints/${rallyId}/${trackId}.json`,
        params: {
            token: token,
            type: 'waypoints',
        },
    });
}



export function getNeutralizationsForTrack(rallyId : number, trackId : number , token : string) {
    return axios({
        method: 'GET',
        url: `https://rest.anube.es/rallyrest/default/api/neutralizations/${rallyId}/${trackId}.json`,
        params: {
            token: token,
            type: 'waypoints2',
        },
    });
}

export function getSpeedZoneWaypointsForTrack(rallyId : number, trackId : number, token : string) {
    return axios({
        method: 'GET',
        url: `https://rest.anube.es/rallyrest/default/api/speed_zones/${rallyId}/${trackId}.json`,
        params: {
            token: token,
        },
    });
}


import { Form, InputNumber, Radio, Select, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { TypeImpulse } from '../../../constants/typeImpulseEnum'
import CustomInputCoordinates from '../../CustomInputCoordinates'
import { CoordinatesRules } from '../../../constants/coordinatesRules'
import { GenericMode } from '../../../constants/genericModeEnum'

interface Props {
    criterias: CriteriaAPI[]
    entryCoords: string | undefined
    exitCoords: string | undefined
}

/**
 * Different components of the form that need the generic speed to work
 * @returns Different form items
 */
const BaseSpecificGenericSpeed = ({ criterias, entryCoords, exitCoords }: Props) => {
    const [impulsesValue, setImpulsesValue] = useState<number>(() => {
        const item = localStorage.getItem('typeImpulse')
        if (item) return Number(item)
        return TypeImpulse.METERS
    })
    const [mode, setMode] = useState<number>(() => {
        const item = localStorage.getItem('mode')
        if (item) return Number(item)
        return GenericMode.COORDINATES
    })

    useEffect(() => {
        localStorage.setItem('typeImpulse', impulsesValue.toString())
    }, [impulsesValue])
    useEffect(() => {
        localStorage.setItem('mode', mode.toString())
    }, [mode])
    const initialValueMaxSpeed = 140
    const initialValueMinSeconds = 1
    const exitThreshold = 25
    const threshold = 25
    const entryThreshold = 25
    return (
        <>
            <Form.Item name={['mode', 'genericSpeed']} label="Mode" className="mb-0" initialValue={mode}>
                <Radio.Group
                    onChange={e => {
                        setMode(e.target.value)
                    }}
                    value={mode}
                >
                    <Radio.Button value={GenericMode.TRACK}>Track</Radio.Button>
                    <Radio.Button value={GenericMode.COORDINATES}>Coordinates</Radio.Button>
                    <Radio.Button value={GenericMode.DATES}>Dates</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {mode === GenericMode.TRACK && (
                <Form.Item
                    name={['threshold', 'genericSpeed']}
                    className="m-0 w-full"
                    initialValue={threshold}
                    label={
                        <div>
                            <Tooltip title="Deviaton meters that the participant can have to get off the trackt">
                                Deviaton meters
                            </Tooltip>
                        </div>
                    }
                >
                    <InputNumber className=" w-full" precision={0} step={1} min={1} value={entryThreshold} />
                </Form.Item>
            )}
            {mode === GenericMode.COORDINATES && (
                <>
                    <Form.Item
                        name={['entryCoords', 'genericSpeed']}
                        className="m-0 w-full"
                        label={
                            <div>
                                <Tooltip title="Coordinates of the entry point">Entry coords</Tooltip>
                            </div>
                        }
                        initialValue={entryCoords}
                        rules={CoordinatesRules}
                    >
                        <CustomInputCoordinates defaultValue={entryCoords} />
                    </Form.Item>
                    <Form.Item
                        name={['entryThreshold', 'genericSpeed']}
                        className="m-0 w-full"
                        initialValue={entryThreshold}
                        label={
                            <div>
                                <Tooltip title="Radius of the maximum radius that the participant can have to get the entry point">
                                    Entry radius
                                </Tooltip>
                            </div>
                        }
                    >
                        <InputNumber className=" w-full" precision={0} step={1} min={0} value={entryThreshold} />
                    </Form.Item>

                    <Form.Item
                        name={['exitCoords', 'genericSpeed']}
                        className="m-0 w-full"
                        label={
                            <div>
                                <Tooltip title="Coordinates of the exit point">Exit coords</Tooltip>
                            </div>
                        }
                        initialValue={exitCoords}
                        rules={CoordinatesRules}
                    >
                        <CustomInputCoordinates defaultValue={exitCoords} />
                    </Form.Item>
                    <Form.Item
                        name={['exitThreshold', 'genericSpeed']}
                        className="m-0 w-full"
                        initialValue={exitThreshold}
                        label={
                            <div>
                                <Tooltip title="Radius of the maximum radius that the participant can have to get the exit point">
                                    Exit radius
                                </Tooltip>
                            </div>
                        }
                    >
                        <InputNumber className=" w-full" precision={0} step={1} min={0} defaultValue={exitThreshold} />
                    </Form.Item>
                </>
            )}
                <>
                    <Form.Item name={'criterias'} label="Criterias" className="mb-0" initialValue={criterias[0]}>
                        <SelectCriterias criterias={criterias} />
                    </Form.Item>
                    <Form.Item
                        name={['maxSpeed', 'genericSpeed']}
                        label="Max speed"
                        className="mb-0"
                        initialValue={initialValueMaxSpeed}
                    >
                        <InputNumber
                            className=" w-full"
                            min={1}
                            defaultValue={initialValueMaxSpeed}
                            precision={0}
                        ></InputNumber>
                    </Form.Item>
                    <Form.Item name={['tolerance', 'genericSpeed']} label="Tolerance" className="mb-0" initialValue={0}>
                        <InputTolerance criteria={criterias[0]} />
                    </Form.Item>
                    <Form.Item
                        name={['minSeconds', 'genericSpeed']}
                        label="Min Seconds"
                        className="mb-0"
                        initialValue={initialValueMinSeconds}
                    >
                        <InputNumber
                            className=" w-full"
                            min={1}
                            defaultValue={initialValueMinSeconds}
                            precision={0}
                        ></InputNumber>
                    </Form.Item>

                    <Form.Item
                        name={'impulseUnit'}
                        label="Impulses in "
                        className="mb-0"
                        initialValue={impulsesValue}
                    >
                        <Radio.Group
                            onChange={e => {
                                setImpulsesValue(e.target.value)
                            }}
                            value={impulsesValue}
                        >
                            <Radio value={TypeImpulse.METERS}>Meters</Radio>
                            <Radio value={TypeImpulse.SECONDS}>Seconds</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name={'impulsesValue'} label="Value of impulse" className="mb-0" initialValue={impulsesValue}>
                        <InputImpulses impulsesValue={impulsesValue} />
                    </Form.Item>
                </>
        </>
    )
}

interface InputToleranceProps {
    criteria: CriteriaAPI
    [x: string]: any
}

interface ValueProps {
    impulsesValue: number
    [x: string]: any
}

const SelectCriterias = ({ criterias, ...restProps }: any) => {
    const FIAFinded = criterias.find((e: CriteriaAPI) => e.description === 'FIA')
    const [criteria, setCriteria] = useState<CriteriaAPI>(FIAFinded ? FIAFinded : criterias[0])

    const handleCriteriaChange = (selectedOption: any) => {
        const criteriaFinded = criterias.find((e: any) => e.short_name === selectedOption)
        if (criteriaFinded) setCriteria(criteriaFinded)
    }
    useEffect(() => {
        restProps.onChange(criteria)
    }, [criteria])
    return (
        <Select
            value={criteria.short_name}
            onChange={handleCriteriaChange}
            options={criterias.map((c: CriteriaAPI) => ({ label: c.description, value: c.short_name }))}
        ></Select>
    )
}
const InputTolerance = ({ criteria, ...restProps }: InputToleranceProps) => {
    useEffect(() => {
        restProps.onChange(criteria.diff_bonus)
    }, [criteria])
    return (
        <InputNumber className=" w-full" value={criteria.diff_bonus} min={0} precision={0} {...restProps}></InputNumber>
    )
}

const InputImpulses = ({ impulsesValue, ...restProps }: ValueProps) => {
    useEffect(() => {
        restProps.onChange(impulsesValue)
    }, [impulsesValue])

    return (
        <InputNumber
            className=" w-full"
            value={impulsesValue}
            defaultValue={impulsesValue}
            min={1}
            precision={0}
            {...restProps}
        ></InputNumber>
    )
}

export default BaseSpecificGenericSpeed

import { all } from 'ol/loadingstrategy'

import * as turf from '@turf/turf'
import {
    deleteConsecutives,
    getFirstIndexInArea,
    getFirstIndexOutOfArea,
    getIndexesInArea,
    getLastIndexInArea,
    getLastIndexOutOfArea,
} from '../../outtrack/common'
import { byNumber } from '../../sortingfunctions'
import { getClosestEntryIndex, getClosestExitIndex } from '../customZoneAnalyzer/customZoneFunctions'

export const sortByFinishTime = (results: SpeedZoneResult[], ascending: boolean = true) => {
    const sortedResults = results.sort((a, b) =>
        byFinishTime({ number: a.number, zones: a.data.zones }, { number: b.number, zones: b.data.zones })
    )
    return ascending ? sortedResults : sortedResults.reverse()
}

export const byFinishTime = (zonesA: SortingSpeed, zonesB: SortingSpeed) => {
    if (
        zonesA.zones.length > 0 &&
        zonesB.zones.length > 0 &&
        zonesA.zones[zonesA.zones.length - 1][0].analysisInfo.exitInfo &&
        zonesB.zones[zonesB.zones.length - 1][0].analysisInfo.exitInfo
    ) {
        const a = zonesA.zones[zonesA.zones.length - 1][0].analysisInfo.exitInfo.point[2]
        const b = zonesB.zones[zonesB.zones.length - 1][0].analysisInfo.exitInfo.point[2]

        if (a !== null && b !== null) {
            return a > b ? 1 : -1
        } else if (a !== null && b === null) {
            return -1
        } else if (a === null && b !== null) {
            return 1
        }
    }

    if (zonesA.zones.length > 0 && zonesB.zones.length < 1) {
        return 1
    } else if (zonesA.zones.length < 1 && zonesB.zones.length > 0) {
        return -1
    }
    return byNumber(zonesA.number, zonesB.number)
}

export function getAverageSpeed(fromIndex: number, toIndex: number, track: number[][]) {
    let speedAverage = 0
    for (let i = fromIndex; i <= toIndex; i++) {
        speedAverage += track[i][3]
    }

    speedAverage /= toIndex - fromIndex + 1
    return Math.floor(speedAverage)
}

function getSpeedZoneIndexes(
    speedZone: InitEndZone,
    participantTrack: number[][],
    threshold: number,
    toleranceRadius: boolean
) {
    // let points =[]
    // if (participantTrack.length >0){
    //     let initp = turf.point([speedZone.init.longitude,speedZone.init.latitude])
    //     let endp =turf.point([speedZone.end.longitude,speedZone.end.latitude])
    //     let a = turf.lineString(participantTrack)
    //     let sliced =turf.lineSlice([speedZone.init.longitude,speedZone.init.latitude], [speedZone.end.longitude,speedZone.end.latitude],a)

    //     participantTrack.forEach( (track,index) => {
    //         let point =  turf.point([track[0],track[1]])
    //         let isPointOnLine=turf.booleanPointOnLine(point, sliced)

    //         if (isPointOnLine){
    //             // console.log(turf.distance(initp,point))
    //             if(turf.distance(initp,point)>90/1000 && turf.distance(endp,point)>90/1000){
    //                 points.push(index)
    //             }
    //         }
    //     })
    // }
    let firstIndex: number | null = getLastIndexInArea(
        speedZone.init.longitude,
        speedZone.init.latitude,
        toleranceRadius ? 90 : threshold,
        participantTrack,
        0,
        null
    )
    let lastIndex: number | null = getLastIndexInArea(
        speedZone.end.longitude,
        speedZone.end.latitude,
        toleranceRadius ? 90 : threshold,
        participantTrack,
        0,
        null
    )
    let allFirstIndex: number[] = getIndexesInArea(
        speedZone.init.longitude,
        speedZone.init.latitude,
        toleranceRadius ? 90 : threshold,
        participantTrack,
        0,
        null
    )
    let allLastIndex: number[] = getIndexesInArea(
        speedZone.end.longitude,
        speedZone.end.latitude,
        toleranceRadius ? 90 : threshold,
        participantTrack,
        0,
        null
    )
    if (firstIndex !== null) {
        firstIndex = toleranceRadius ? firstIndex : getClosestEntryIndex(speedZone.init, participantTrack, firstIndex)
        allFirstIndex = getIndexesInArea(
            speedZone.init.longitude,
            speedZone.init.latitude,
            toleranceRadius ? 90 : threshold,
            participantTrack,
            0,
            null
        )
    }

    if (lastIndex !== null) {
        lastIndex = toleranceRadius
            ? getFirstIndexInArea(
                  speedZone.end.longitude,
                  speedZone.end.latitude,
                  toleranceRadius ? 90 : threshold,
                  participantTrack,
                  0,
                  null
              )
            : getClosestEntryIndex(speedZone.end, participantTrack, lastIndex)

        allLastIndex = getIndexesInArea(
            speedZone.end.longitude,
            speedZone.end.latitude,
            toleranceRadius ? 90 : threshold,
            participantTrack,
            0,
            null
        )
    }
    let allIndexes: number[][] = []
    if (allFirstIndex.length !== 0 && allLastIndex.length !== 0) {
        allFirstIndex = deleteConsecutives(allFirstIndex, true)
        allLastIndex = deleteConsecutives(allLastIndex, false)
        if (allFirstIndex.length === allLastIndex.length) {
            for (let index = 0; index < allFirstIndex.length; index++) {
                let fi: number | null
                let ei: number | null

                fi = toleranceRadius
                    ? getLastIndexInArea(
                          speedZone.init.longitude,
                          speedZone.init.latitude,
                          toleranceRadius ? 90 : threshold,
                          participantTrack,
                          allFirstIndex[index],
                          null
                      )
                    : getClosestEntryIndex(speedZone.init, participantTrack, allFirstIndex[index])
                ei = toleranceRadius
                    ? getFirstIndexInArea(
                          speedZone.end.longitude,
                          speedZone.end.latitude,
                          toleranceRadius ? 90 : threshold,
                          participantTrack,
                          allLastIndex[allLastIndex.length - index],
                          null
                      )
                    : getClosestEntryIndex(speedZone.end, participantTrack, allLastIndex[allLastIndex.length - index])
                if (fi && ei) {
                    allIndexes.push([fi, ei])
                }
            }
            return {
                oneIndex: [firstIndex, lastIndex],
                allIndexes: allIndexes,
            }
        } else {
            if (allLastIndex.length > allFirstIndex.length) {
                for (let i = 0; i < allFirstIndex.length; i++) {
                    let min = 0
                    for (let j = 0; j < allLastIndex.length; j++) {
                        if (min > allLastIndex[j] - allFirstIndex[i]) {
                            allLastIndex.splice(j, 1)
                            j--
                        } else {
                            allIndexes.push([allFirstIndex[i], allLastIndex[j]])
                            if (!(i < allFirstIndex.length - 1)) {
                                allLastIndex.splice(j + 1, allLastIndex.length - 1 - j + 1)
                            } else {
                                break
                            }
                        }
                    }
                }
            }
            return {
                oneIndex: [firstIndex, lastIndex],
                allIndexes: allIndexes,
            }
        }
    } else {
        return {
            oneIndex: [firstIndex, lastIndex],
            allIndexes: allIndexes,
        }
    }
}

export function chooseMostFavorableEntryExitInfo(
    speedZone: InitEndZone,
    participantTrackA: number[][],
    participantTrackB: number[][],
    threshold: number,
    consecSecs: number,
    maxSpeed: number,
    toleranceRadius: boolean
) {
    let trackSliceA: TrackSlice = getSpeedZoneIndexes(speedZone, participantTrackA, threshold, toleranceRadius)
    let trackSliceB: TrackSlice = getSpeedZoneIndexes(speedZone, participantTrackB, threshold, toleranceRadius)
    const trackAInfoOneIndex: TrackSpeed = {
        participantTrackType: 'primary',
        speedInfo: getEntryExitInfo(trackSliceA.oneIndex, participantTrackA, consecSecs, maxSpeed, -1),
    }

    const trackAInfoAll: TrackSpeedAll = {
        participantTrackType: 'primary',
        speedInfo: trackSliceA.allIndexes.map(a => {
            const speedInfo: SpeedInfo = getEntryExitInfo(a, participantTrackA, consecSecs, maxSpeed, -1)
            return speedInfo
        }),
    }
    const trackBInfoOneIndex: TrackSpeed = {
        participantTrackType: 'secondary',
        speedInfo: getEntryExitInfo(trackSliceB.oneIndex, participantTrackB, consecSecs, maxSpeed, -1),
    }

    const trackBInfoAll: TrackSpeedAll = {
        participantTrackType: 'secondary',
        speedInfo: trackSliceB.allIndexes.map(a => {
            const speedInfo: SpeedInfo = getEntryExitInfo(a, participantTrackB, consecSecs, maxSpeed, -1)
            return speedInfo
        }),
    }

    let trackAInfo: TrackInfoSped = {
        oneIndex: trackAInfoOneIndex,
        all: trackAInfoAll,
    }
    let trackBInfo: TrackInfoSped = {
        oneIndex: trackBInfoOneIndex,
        all: trackBInfoAll,
    }
    if (trackAInfo.oneIndex.speedInfo.reached !== trackBInfo.oneIndex.speedInfo.reached) {
        return trackAInfo.oneIndex.speedInfo.reached ? trackAInfo : trackBInfo
    }

    if (!trackAInfo.oneIndex.speedInfo.reached) {
        return trackAInfo
    }

    if (
        (trackAInfo.oneIndex.speedInfo.fastestRange !== null) !==
        (trackBInfo.oneIndex.speedInfo.fastestRange !== null)
    ) {
        return trackAInfo.oneIndex.speedInfo.fastestRange === null ? trackAInfo : trackBInfo
    }

    if (trackAInfo.oneIndex.speedInfo.fastestRange !== null && trackBInfo.oneIndex.speedInfo.fastestRange !== null) {
        const speedA = trackAInfo.oneIndex.speedInfo.fastestRange.fastestPointInfo.point[3]
        const speedB = trackBInfo.oneIndex.speedInfo.fastestRange.fastestPointInfo.point[3]

        return speedA < speedB ? trackAInfo : trackBInfo
    }

    if (
        (trackAInfo.oneIndex.speedInfo.singleFastestInfo !== null) !==
        (trackBInfo.oneIndex.speedInfo.singleFastestInfo !== null)
    ) {
        return trackAInfo.oneIndex.speedInfo.singleFastestInfo === null ? trackBInfo : trackAInfo
    }

    if (!trackAInfo.oneIndex.speedInfo.singleFastestInfo && trackBInfo.oneIndex.speedInfo.singleFastestInfo) {
        return trackBInfo
    }

    const speedA = trackAInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
        ? trackAInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
        : 0
    const speedB = trackBInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
        ? trackBInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
        : 0
    return speedA < speedB ? trackAInfo : trackBInfo
}

export function getEntryExitInfo(
    trackSlice: (number | null)[],
    participantTrack: number[][],
    consecSecs: number,
    maxSpeed: number,
    minSpeed: number
) {
    const [firstIndex, lastIndex] = trackSlice
    const reached = firstIndex !== null && lastIndex !== null
    const overlapping = firstIndex !== null && lastIndex !== null ? firstIndex > lastIndex : false

    const fastestRange =
        !reached || overlapping
            ? null
            : getFastestRange(firstIndex, lastIndex, participantTrack, consecSecs, maxSpeed, minSpeed)

    const entryInfo =
        firstIndex === null
            ? null
            : {
                  index: firstIndex,
                  point: participantTrack[firstIndex],
              }
    const exitInfo =
        lastIndex === null
            ? null
            : {
                  index: lastIndex,
                  point: participantTrack[lastIndex],
              }
    let cont: number = 0
    const infringements: SpeedInfringements[] = []
    if (firstIndex !== null && lastIndex !== null && !overlapping) {
        let firstTimeInfringement: number = 0
        for (let index = firstIndex; index <= lastIndex; index++) {
            const currPoint = participantTrack[index]

            if (currPoint[3] > maxSpeed) {
                if (cont === 0) firstTimeInfringement = index
                cont++
                if (index === lastIndex) {
                    if (cont >= consecSecs) {
                        let maxSpeed = -1
                        let maxSpeedCoordinates: any = []

                        participantTrack.forEach((e, index2) => {
                            if (index2 >= firstTimeInfringement && index2 < index-1) {
                                const value = e[3]
                                if (value > maxSpeed) {
                                    maxSpeed = value
                                    maxSpeedCoordinates = [e[0], e[1]]
                                }
                            }
                        })
                        infringements.push({
                            firstInfringementIndex: firstTimeInfringement,
                            firstPointParticipant: participantTrack[firstTimeInfringement],
                            lastInfringementIndex: index-1,
                            lastPointParticipant: participantTrack[index-1],
                            maxSpeed: maxSpeed,
                            maxSpeedCoordinates: maxSpeedCoordinates,
                            timeInfringementCount: cont,
                        })
                    }
                }
            } else {
                if (cont >= consecSecs) {
                    let maxSpeed = -1
                    let maxSpeedCoordinates: any = []

                    participantTrack.forEach((e, index2) => {
                        if (index2 >= firstTimeInfringement && index2 < index-1) {
                            const value = e[3]
                            if (value > maxSpeed) {
                                maxSpeed = value
                                maxSpeedCoordinates = [e[0], e[1]]
                            }
                        }
                    })
                    infringements.push({
                        firstInfringementIndex: firstTimeInfringement,
                        firstPointParticipant: participantTrack[firstTimeInfringement],
                        lastInfringementIndex: index-1,
                        lastPointParticipant: participantTrack[index-1],
                        maxSpeed: maxSpeed,
                        maxSpeedCoordinates: maxSpeedCoordinates,
                        timeInfringementCount: cont,
                    })
                }
                firstTimeInfringement = 0
                cont = 0
            }
        }
    }
    let singleFastestInfo = null
    if (firstIndex !== null && lastIndex !== null && !overlapping) {
        for (let i = firstIndex; i <= lastIndex; i++) {
            const currPoint = participantTrack[i]
            if (singleFastestInfo === null || singleFastestInfo.point[3] < currPoint[3]) {
                let duration = 1
                if (i > 0) {
                    duration = currPoint[2] - participantTrack[i - 1][2]
                }
                singleFastestInfo = {
                    index: i,
                    point: currPoint,
                    duration,
                }
            }
        }
    }

    let singleSlowestInfo = null
    if (firstIndex !== null && lastIndex !== null && !overlapping) {
        for (let i = firstIndex; i <= lastIndex; i++) {
            const currentPoint = participantTrack[i]
            if (singleSlowestInfo === null || currentPoint[3] < singleSlowestInfo.point[3]) {
                let duration = 1
                if (i > 0) {
                    duration = currentPoint[2] - participantTrack[i - 1][2]
                }
                singleSlowestInfo = {
                    index: i,
                    point: currentPoint,
                    duration,
                }
            }
        }
    }

    return {
        reached,
        overlapping,
        entryInfo,
        exitInfo,
        singleFastestInfo,
        fastestRange,
        singleSlowestInfo,
        infringements: infringements,
    }
}
const DURATION_TIMEOUT = 5

function getFastestRange(
    fromIndex: number,
    toIndex: number,
    participantTrack: number[][],
    consecSecs: number,
    maxSpeed: number,
    minSpeed: number
) {
    let currentRange = null
    let fastestRange = null
    let slowestRange = null
    if (isNaN(maxSpeed) || maxSpeed === 0) return fastestRange
    for (let i = fromIndex; i <= toIndex; i++) {
        let duration = i === 0 ? 1 : participantTrack[i][2] - participantTrack[i - 1][2]
        const currentSpeed = participantTrack[i][3]
        if (!isNaN(maxSpeed)) {
            if (currentSpeed > maxSpeed) {
                if (currentRange === null || duration >= DURATION_TIMEOUT) {
                    fastestRange = chooseNewSpeedRange(currentRange, fastestRange, consecSecs)

                    currentRange = {
                        duration: duration >= DURATION_TIMEOUT ? 1 : duration,
                        fastestPointInfo: {
                            index: i,
                            point: participantTrack[i],
                        },
                    }
                } else {
                    currentRange.duration += duration
                    if (currentRange.fastestPointInfo.point[3] < currentSpeed) {
                        currentRange.fastestPointInfo = {
                            index: i,
                            point: participantTrack[i],
                        }
                    }
                }
            } else if (currentRange !== null) {
                fastestRange = chooseNewSpeedRange(currentRange, fastestRange, consecSecs)
                currentRange = null
            }
        } else {
            if (currentSpeed < minSpeed) {
                if (currentRange === null || duration >= DURATION_TIMEOUT) {
                    slowestRange = chooseNewSpeedRange(currentRange, slowestRange, consecSecs)

                    currentRange = {
                        duration: duration >= DURATION_TIMEOUT ? 1 : duration,
                        fastestPointInfo: {
                            index: i,
                            point: participantTrack[i],
                        },
                    }
                } else {
                    currentRange.duration += duration
                    if (currentRange.fastestPointInfo.point[3] < currentSpeed) {
                        currentRange.fastestPointInfo = {
                            index: i,
                            point: participantTrack[i],
                        }
                    }
                }
            } else {
                if (currentRange !== null && !isNaN(maxSpeed)) {
                    slowestRange = chooseNewSpeedRange(currentRange, slowestRange, consecSecs)
                    currentRange = null
                }
            }
        }
    }

    fastestRange = chooseNewSpeedRange(currentRange, fastestRange, consecSecs)

    return fastestRange
}

function chooseNewSpeedRange(currentRange: FastestRange | null, fastestRange: FastestRange | null, consecSecs: number) {
    if (currentRange === null) {
        return fastestRange
    }

    if (currentRange.duration < consecSecs) {
        return fastestRange
    }

    if (fastestRange !== null && currentRange.fastestPointInfo.point[3] <= fastestRange.fastestPointInfo.point[3]) {
        return fastestRange
    }

    return currentRange
}

export function waypointsToSpeedZones(waypoints: SpeedZoneAPI[], threshold: number, toleranceRadius: boolean) {
    waypoints = waypoints.filter(e => e.type === 'DZ' || e.type === 'FZ')

    const res: InitEndZone[] = []
    waypoints
        .filter(e => e.type === 'DZ')
        .forEach(dzWaypoint => {
            const fzWaypoint = waypoints.find(e => e.type === 'FZ' && e.number === dzWaypoint.number)
            if (fzWaypoint !== undefined) {
                const init: SpeedZone = {
                    ...dzWaypoint,
                    radius: toleranceRadius ? 90 : threshold,
                }
                const end: SpeedZone = {
                    ...fzWaypoint,
                    radius: toleranceRadius ? 90 : threshold,
                }

                res.push({
                    id: `${init.id}-${end.id}`,
                    init: init,
                    end: end,
                })
            }
        })

    return res
}

export function createAnalisysOfSpeedZones(
    speedZones: any[],
    params: ParamsAnalyzerSpeedZone,
    mainParticipantTrack: number[][],
    secondaryParticipantTrack: number[][],
    state: number
) {
    return speedZones.map(speedZone => {
        const maxUnpenalizableSpeed = speedZone.init.speed! + params.penaltyTolerance
        const info: TrackInfoSped = chooseMostFavorableEntryExitInfo(
            speedZone,
            mainParticipantTrack,
            secondaryParticipantTrack,
            params.threshold,
            params.consecSecs,
            maxUnpenalizableSpeed,
            params.toleranceRadius
        )
        if (info.all.speedInfo.length > 0) {
            const result = info.all?.speedInfo.map(b => {
                const maxSpeed = speedZone.init.speed!
                const cardSpeedPoint =
                    b.fastestRange !== null
                        ? b.fastestRange.fastestPointInfo.point
                        : b.singleFastestInfo !== null
                          ? b.singleFastestInfo.point
                          : null
                const cardExcess = cardSpeedPoint !== null ? Math.max(0, cardSpeedPoint[3] - maxSpeed) : 0
                const penalization = b.fastestRange === null ? 0 : cardExcess * params.penaltyFactor

                return {
                    serverInfo: {
                        zoneNumber: speedZone.init.number,
                        zoneName: speedZone.init.name,
                        maxSpeed,
                        areaPoints: {
                            init: speedZone.init,
                            end: speedZone.end,
                        },
                    },
                    analysisInfo: {
                        id: `${speedZone.init.id}-${speedZone.end.id}`,
                        participantTrackType: info.all?.participantTrackType,
                        reached: b.reached,
                        overlapping: b.overlapping,
                        entryInfo: b.entryInfo,
                        exitInfo: b.exitInfo,
                        singleFastestInfo: b.singleFastestInfo,
                        fastestRange: b.fastestRange,
                        infringements: b.infringements,
                        link: `https://anubesport.com/tracking/?rally=rally${params.rally}&port=auto&token=${params.token}&map=satellite&type=animation&participants=${params.participantUINumber}&participant_ids=${params.participant.id}&from=${b.entryInfo?.point[2]}&to=${b.exitInfo?.point[2]}`,
                        penalization,
                        cardSpeedPoint,
                        cardExcess,
                        maxUnpenalizableSpeed,
                    },
                    state: state,
                    params: {
                        toleranceRadius: params.toleranceRadius,
                        penaltyTolerance: params.penaltyTolerance,
                        consecSecs: params.consecSecs,
                        threshold: params.threshold,
                    },
                }
            })
            return result
        } else {
            const maxSpeed = speedZone.init.speed!
            const cardSpeedPoint =
                info.oneIndex.speedInfo.fastestRange !== null
                    ? info.oneIndex.speedInfo.fastestRange.fastestPointInfo.point
                    : info.oneIndex.speedInfo.singleFastestInfo !== null
                      ? info.oneIndex.speedInfo.singleFastestInfo.point
                      : null
            const cardExcess = cardSpeedPoint !== null ? Math.max(0, cardSpeedPoint[3] - maxSpeed) : 0
            const penalization = info.oneIndex.speedInfo.fastestRange === null ? 0 : cardExcess * params.penaltyFactor

            return [
                {
                    serverInfo: {
                        zoneNumber: speedZone.init.number,
                        zoneName: speedZone.init.name,
                        maxSpeed,
                        areaPoints: {
                            init: speedZone.init,
                            end: speedZone.end,
                        },
                    },
                    analysisInfo: {
                        id: `${speedZone.init.id}-${speedZone.end.id}`,
                        participantTrackType: info.oneIndex.participantTrackType,
                        reached: info.oneIndex.speedInfo.reached,
                        overlapping: info.oneIndex.speedInfo.overlapping,
                        entryInfo: info.oneIndex.speedInfo.entryInfo,
                        exitInfo: info.oneIndex.speedInfo.exitInfo,
                        singleFastestInfo: info.oneIndex.speedInfo.singleFastestInfo,
                        fastestRange: info.oneIndex.speedInfo.fastestRange,
                        infringements: info.oneIndex.speedInfo.infringements,
                        link: `https://anubesport.com/tracking/?rally=rally${params.rally}&port=auto&token=${params.token}&map=satellite&type=animation&participants=${params.participantUINumber}&participant_ids=${params.participant.id}&from=${info.oneIndex.speedInfo.entryInfo?.point[2]}&to=${info.oneIndex.speedInfo.exitInfo?.point[2]}`,
                        penalization,
                        cardSpeedPoint,
                        cardExcess,
                        maxUnpenalizableSpeed,
                    },
                    state: state,
                    params: {
                        toleranceRadius: params.toleranceRadius,
                        penaltyTolerance: params.penaltyTolerance,
                        consecSecs: params.consecSecs,
                        threshold: params.threshold,
                    },
                },
            ]
        }
    })
}

import React, { useState } from 'react'
import ParticipantCard from '../participantCard'
import RangeBar from '../../RangeBar/rangeBar'

import moment from 'moment'
import 'moment-timezone'

import './waypoints-card.css'
import DnsDnf from './DnsDnf'
import PositionsLost from '../../PositionsLost'
import { calculateLostPositions } from '../../../utils'
import WaypointsFailed from './WaypointsFailed'
import { FaCheck } from 'react-icons/fa6'
//
const WaypointsCard = ({ className, onClick, result, openPopup }: any) => {
    let lastWaypoint: number
    let inactiveStartsAt: number
    let positions = calculateLostPositions(result.disconnectedGpsTrack)
    let findedWpId: number | undefined
    if (result.data.waypointFinded) {
        findedWpId = result.data.waypointFinded[0].waypointFinded?.wpId
    }
    const valueDnsDnf: string = result.data.valueDnsDnf
    const unmatchedWaypoints: RangeBar[] = result.data.waypoints.map(
        (waypoint: Waypoints, i: number, waypoints: any) => {
            if (waypoint.id === findedWpId) {
                return {
                    edges: [(i / waypoints.length) * 100, (i / waypoints.length) * 100],
                    disabled: waypoint.excluded || false,
                    firstUnfinished: true,
                }
            }
            if (waypoint.matchedGps) {
                return {
                    edges: [(i / waypoints.length) * 100, ((i + 1) / waypoints.length) * 100],
                    disabled: waypoint.excluded || false,
                    matchedGps: true,
                }
            }
            if (waypoint.matched) {
                return {
                    edges: [-1, -1],
                    disabled: false,
                    matched: true,
                }
            }

            return {
                edges: [(i / waypoints.length) * 100, ((i + 1) / waypoints.length) * 100],
                disabled: waypoint.excluded || false,
            }
        }
    )
    const unmatchedWaypointsFiltered = unmatchedWaypoints.filter(e => !e.matched)
    for (let i = (result.data as WaypointsReport).waypoints.length - 1; i >= 0; i--) {
        if (
            (result.data as WaypointsReport).waypoints[i].matched ||
            (result.data as WaypointsReport).waypoints[i].matchedGps
        ) {
            lastWaypoint = i + 1
            break
        }
    }
    inactiveStartsAt = (lastWaypoint! / (result.data as WaypointsReport).waypoints.length) * 100 || 0
    if (!unmatchedWaypoints.length) {
        return <></>
    }
    const waypointFailed: Waypoint[] = result.data.waypoints.filter((e: Waypoint) => !e.matched && !e.matchedGps)
    const [strikethrough, setStrikethrough] = useState(Array(waypointFailed.length).fill(false))
    let collapseData: any = <></>
    if (waypointFailed.length > 0) {
        collapseData = waypointFailed.map((waypoint, index) => {
            return (
                <div
                    className={` border-solid-1 hover:bg-neutral- ' + m-1 flex flex-row justify-center text-nowrap rounded border border-neutral-300 bg-neutral-200 text-right hover:cursor-pointer hover:font-bold
                        ${strikethrough[index] ? 'line-through' : ''}
                            `}
                    onClick={() => openPopup(waypoint.name)}
                    key={index}
                >
                    <div
                        className="flex flex-row space-x-1 
                    self-center rounded "
                    >
                        <div className=" italic underline">Waypoint Failed:</div>
                        <div className="font-bold">{waypoint.name}</div>
                        <a
                            className=""
                            target="_blank"
                            rel="noopener noreferrer"
                            href={waypoint.adminLink}
                            title="Waypoint Link"
                        >
                            Waypoint link
                        </a>
                        {/* <div
                            onClick={(event) => {
                                event.stopPropagation()
                                fetch(
                                    'https://dev.anube.es/rallyrest/default/api/waypoint_checks/2050.json?token=38Z8IKVcmA',
                                    {
                                        method: 'PUT',
                                        headers: {
                                            'Content-Type': 'application/x-www-form-urlencoded',
                                        },
                                        body:
                                            'json_data=' +
                                            JSON.stringify([
                                                { ut: 1, wp_id: waypoint.id, dorsal_id: result.id },
                                            ]),
                                    }
                                )
                                    .then(response => {
                                        if (response.ok) {
                                            const newStrikethrough = [...strikethrough]
                                            newStrikethrough[index] = true
                                            setStrikethrough(newStrikethrough)
                                        } else {
                                            console.error('Error en la respuesta:', response.statusText)
                                        }
                                    })
                                    .catch(error => {
                                        console.error('Error:', error)
                                    })
                            }}
                        >
                            <FaCheck />
                        </div> */}
                    </div>
                </div>
            )
        })
    }
    return (
        <ParticipantCard
            number={result.number}
            onClick={() => onClick(result)}
            className={`${className} waypoint-card m-1`}
            collapseData={collapseData}
        >
            <RangeBar
                ranges={unmatchedWaypointsFiltered}
                inactiveStart={inactiveStartsAt}
                width={100}
                theme="match-track"
            />
            <WaypointsFailed waypointsFailedCount={waypointFailed.length}></WaypointsFailed>
            <PositionsLost positions={positions} />
            <DnsDnf dnsDnf={valueDnsDnf} />
        </ParticipantCard>
    )
}

export default WaypointsCard

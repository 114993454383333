import React, { ReactNode, useEffect, useState } from 'react'
import moment from 'moment'
import 'moment-timezone'
import '../summary_card.css'
import { Button, Collapse, Modal, Popover, type CollapseProps } from 'antd'
import { calculateLostPositions, timeUtcToHHMMSS, timespanToHHMMSS } from '../../../utils'
import ParticipantCard from '../participantCard'
import { GiFilmProjector } from 'react-icons/gi'
import PositionsLost from '../../PositionsLost'
import { FaXmark } from 'react-icons/fa6'
import { TiTick } from 'react-icons/ti'
import { CodeSandboxSquareFilled, EyeTwoTone } from '@ant-design/icons'

enum ParticipantState {
    PENDING = 0,
    REJECTED = -1,
    ACCEPTED = 1,
}

const SpeedCard = ({ className, onClick, participantTracks, requestData, result, timezone, updatePointer }: any) => {
    let positions = calculateLostPositions(result.disconnectedGpsTrack)

    const [openModalZone, setOpenModalZone] = useState<number | null>(null)
    const [participantState, setParticipantState] = useState(
        result.data.zones.reduce((acc: any, row: SpeedZoneData[]) => {
            if (!acc[result.id]) {
                acc[result.id] = {}
            }

            row.forEach((zone: SpeedZoneData) => {
                acc[result.id][zone.serverInfo.zoneNumber] = zone.state
            })

            return acc
        }, {})
    )
    useEffect(() => {
        setParticipantState((prevState: any) => {
            const newState = { ...prevState }

            result.data.zones.forEach((row: SpeedZoneData[]) => {
                if (!newState[result.id]) {
                    newState[result.id] = {} 
                }

                row.forEach((zone: SpeedZoneData) => {
                    const zoneNumber = zone.serverInfo.zoneNumber
                    const currentState = newState[result.id][zoneNumber]
                    if(!currentState){
                        newState[result.id][zoneNumber] = zone.state

                    }else {

                        newState[result.id][zoneNumber] = currentState
                    }
                })
            })

            return newState
        })
    }, [result])
    const handleCancel = () => {
        setOpenModalZone(null)
    }

    const handleStateChange = async (
        newState: ParticipantState,
        zoneNumber: number,
        id: number,
        zone: SpeedZoneData
    ) => {
        const updatedState = {
            ...participantState,
            [id]: {
                ...participantState[id],
                [zoneNumber]: newState,
            },
        }
        setParticipantState(updatedState)
        setOpenModalZone(null)

        const put = [
            {
                tramo_id: result.sectionId,
                brand: 'speed_zones',
                dorsal_id: result.id,
                zone_name: zone.serverInfo.zoneName,
                zone_number: zone.serverInfo.zoneNumber,
                data: JSON.stringify([zone]),
                state: newState,
                url_doc: '',
                url_playback: '',
            },
        ]
        const formBody = 'json_data=' + encodeURIComponent(JSON.stringify(put))

        await fetch(
            `https://rest.anube.es/rallyrest/default/api/routes_reports/${result.rally}.json?token=${result.token}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formBody,
            }
        )
    }

    const getButtonForState = (state: ParticipantState, zoneNumber: number, id: number, zone: SpeedZoneData) => {
        const isOpen = openModalZone === zoneNumber
        switch (state) {
            case ParticipantState.PENDING:
                return (
                    <>
                        <span
                            className=" zone-name w-[30px]  text-center align-middle text-slate-500"
                            onClick={() => setOpenModalZone(zoneNumber)}
                        >
                            ---
                        </span>
                        <Modal
                            styles={{ mask: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
                            onCancel={handleCancel}
                            title="Change State"
                            open={isOpen}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}
                        >
                            <div className="flex space-x-2">
                                <Button
                                    className="flex-1"
                                    onClick={() => handleStateChange(ParticipantState.ACCEPTED, zoneNumber, id, zone)}
                                >
                                    Validate {<TiTick className="text-green-500"></TiTick>}
                                </Button>
                                <Button
                                    className="flex-1"
                                    onClick={() => handleStateChange(ParticipantState.REJECTED, zoneNumber, id, zone)}
                                >
                                    Reject <FaXmark className="text-red-500"></FaXmark>
                                </Button>
                            </div>
                        </Modal>
                    </>
                )
            case ParticipantState.REJECTED:
                return (
                    <>
                        <FaXmark
                            className="zone-name h-[20px] w-[30px] align-middle text-red-500"
                            onClick={() => setOpenModalZone(zoneNumber)}
                        ></FaXmark>
                        <Modal
                            styles={{ mask: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
                            onCancel={handleCancel}
                            title="Change State"
                            open={isOpen}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}
                        >
                            <div className="flex space-x-2">
                                <Button
                                    className="flex-1"
                                    onClick={() => handleStateChange(ParticipantState.PENDING, zoneNumber, id, zone)}
                                >
                                    Pending {<span className="text-slate-400">---</span>}
                                </Button>
                                <Button
                                    className="flex-1"
                                    onClick={() => handleStateChange(ParticipantState.ACCEPTED, zoneNumber, id, zone)}
                                >
                                    Validate {<TiTick className="text-green-500"></TiTick>}
                                </Button>
                            </div>
                        </Modal>
                    </>
                )
            case ParticipantState.ACCEPTED:
                return (
                    <>
                        <TiTick
                            className=" zone-name h-[20px] w-[30px] align-middle  text-green-500"
                            onClick={() => setOpenModalZone(zoneNumber)}
                        ></TiTick>
                        <Modal
                            styles={{ mask: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
                            onCancel={handleCancel}
                            title="Change State"
                            open={isOpen}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}
                        >
                            <div className="flex space-x-2">
                                <Button
                                    className="flex-1"
                                    onClick={() => handleStateChange(ParticipantState.REJECTED, zoneNumber, id, zone)}
                                >
                                    Reject {<FaXmark className="text-red-500"></FaXmark>}
                                </Button>
                                <Button
                                    className="flex-1"
                                    onClick={() => handleStateChange(ParticipantState.PENDING, zoneNumber, id, zone)}
                                >
                                    Pending {<span className="text-slate-400">---</span>}
                                </Button>
                            </div>
                        </Modal>
                    </>
                )
            default:
                return <button>Unknown</button>
        }
    }

    const zonesInfo = result.data.zones.map((results: SpeedZoneData[], index1: any) => {
        if (results !== undefined) {
            const speedZonesData = results.map((zone: SpeedZoneData, index2: any) => {
                let maxSpeed
                let maxSpeedTooltipText = ''
                let tagSpeedClassName = 'item waypoint'
                let tagExcessClassName = 'item waypoint'
                let timeInfo: ReactNode = ''
                let link: ReactNode = <div className="zone-name min-w-[15px]"></div>
                let excessInfo = ' - '
                let penalization = ' - '

                if (zone.analysisInfo.reached && !zone.analysisInfo.overlapping) {
                    maxSpeed = zone.analysisInfo.cardSpeedPoint[3] + ' km/h'
                    excessInfo = String(zone.analysisInfo.cardExcess)
                    penalization = String(zone.analysisInfo.penalization)

                    let maxSpeedPoint, maxSpeedDuration
                    if (zone.analysisInfo.fastestRange === null) {
                        tagSpeedClassName += ' ok-tag duration-tag clickable'
                        tagExcessClassName += ' ok-tag'
                        maxSpeedPoint = zone.analysisInfo.singleFastestInfo.point
                        maxSpeedDuration = zone.analysisInfo.singleFastestInfo.duration
                    } else {
                        link = (
                            <GiFilmProjector
                                className="zone-name min-w-[15px]"
                                onClick={() => {
                                    window.open(zone.analysisInfo.link)
                                }}
                            />
                        )
                        tagSpeedClassName += ' error-tag duration-tag clickable'
                        tagExcessClassName += ' error-tag'
                        maxSpeedPoint = zone.analysisInfo.fastestRange.fastestPointInfo.point
                        maxSpeedDuration = zone.analysisInfo.fastestRange.duration
                    }
                    timeInfo = (
                        <>
                            {moment(maxSpeedPoint[2] * 1000)
                                .tz(timezone)
                                .format('HH:mm:ss')}
                        </>
                    )

                    const duration = timespanToHHMMSS(maxSpeedDuration)
                    maxSpeedTooltipText = `(${duration}) seconds`
                } else if (zone.analysisInfo.overlapping) {
                    maxSpeed = <div> Overlap </div>
                    tagSpeedClassName += ' overlapping-tag'
                    tagExcessClassName += ' overlapping-tag'
                } else {
                    maxSpeed = <div> Zone not reached </div>
                    tagSpeedClassName += ' neutral-tag'
                    tagExcessClassName += ' neutral-tag'
                }

                let maxInfringement = null
                if (zone.analysisInfo.infringements.length > 0) {
                    maxInfringement = zone.analysisInfo.infringements.reduce((max, current) => {
                        if (current.maxSpeed > max.maxSpeed) {
                            return current
                        } else if (current.maxSpeed === max.maxSpeed) {
                            if (current.timeInfringementCount > max.timeInfringementCount) {
                                return current
                            }
                        }
                        return max
                    })
                }

                const zones = (
                    <div>
                        {zone.analysisInfo.infringements.map(e => (
                            <div className="border-solid-1 m-1 flex flex-row justify-center text-nowrap rounded border border-neutral-300 bg-neutral-200 text-right text-xs">
                                <div className="flex flex-row space-x-1 self-center rounded ">
                                    <div
                                        onClick={() => {
                                            updatePointer(e.firstPointParticipant[0], e.firstPointParticipant[1])
                                        }}
                                    >
                                        {timeUtcToHHMMSS(e.firstPointParticipant[2], timezone)}
                                    </div>
                                    <div
                                        onClick={() => {
                                            updatePointer(e.lastPointParticipant[0], e.lastPointParticipant[1])
                                        }}
                                    >
                                        {timeUtcToHHMMSS(e.lastPointParticipant[2], timezone)}
                                    </div>
                                </div>
                                <div className="flex flex-row space-x-1">
                                    <span className="p-1 font-bold italic underline">Max speed:</span>
                                    <div
                                        className="rounded bg-[red] p-1"
                                        onClick={() => {
                                            updatePointer(e.maxSpeedCoordinates[0], e.maxSpeedCoordinates[1])
                                        }}
                                    >
                                        {e.maxSpeed} km/h
                                    </div>
                                </div>
                                <div className="flex flex-row space-x-1 ">
                                    <span className="p-1 font-bold italic underline ">Total time spent:</span>
                                    <div className="p-1">{e.timeInfringementCount}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                )

                const panelHeader = (
                    <div key={zone.analysisInfo.id}>
                        <div
                            className="zone-name clickable "
                            onClick={() => {
                                const point = zone.serverInfo.areaPoints?.init
                                if (point && point.longitude && point.latitude) {
                                    updatePointer(point.longitude, point.latitude)
                                }
                            }}
                        >
                            <Popover
                                content={
                                    <span>
                                        SpeedLimit: {zone.serverInfo.maxSpeed} km/h
                                        <br />
                                        Run: {index2 + 1}
                                    </span>
                                }
                                trigger={'hover'}
                            >
                                <b>{zone.serverInfo.zoneName}</b>
                            </Popover>
                        </div>
                        <div
                            className="zone-name clickable "
                            onClick={() => {
                                const point = zone.serverInfo.areaPoints?.end
                                if (point && point.longitude && point.latitude) {
                                    updatePointer(point.longitude, point.latitude)
                                }
                            }}
                        >
                            {zone.serverInfo.areaPoints?.end.name ? '-' + zone.serverInfo.areaPoints?.end.name : ''}
                        </div>
                        <div className="zone-name ml-1 rounded bg-orange-400">{zone.serverInfo.maxSpeed} km/h</div>
                        <div className="zone-name clickable ml-1 mr-1">{timeInfo}</div>
                        <div
                            className={tagSpeedClassName}
                            onClick={() => {
                                if (zone.analysisInfo.reached !== null && !zone.analysisInfo.overlapping) {
                                    const point =
                                        zone.analysisInfo.fastestRange !== null
                                            ? zone.analysisInfo.fastestRange.fastestPointInfo.point
                                            : zone.analysisInfo.singleFastestInfo.point
                                    updatePointer(point[0], point[1])
                                }
                            }}
                        >
                            <Popover
                                content={
                                    maxSpeedTooltipText !== '' && (
                                        <span className="popup-tooltiptext">{maxSpeedTooltipText}</span>
                                    )
                                }
                                trigger={'hover'}
                            >
                                {maxSpeed}
                            </Popover>
                        </div>
                        <Popover content={<span>Time spent</span>} trigger={'hover'}>
                            <div className={tagExcessClassName}>
                                {maxInfringement ? maxInfringement.timeInfringementCount : 0}
                            </div>
                        </Popover>
                        {link}
                        {participantState[result.id] !== undefined &&
                            participantState[result.id][zone.serverInfo.zoneNumber] !== undefined && (
                                <div key={`${index1}-${index2}`} className="zone-name">
                                    {getButtonForState(
                                        participantState[result.id][zone.serverInfo.zoneNumber],
                                        zone.serverInfo.zoneNumber,
                                        result.id,
                                        zone
                                    )}
                                </div>
                            )}
                        {Boolean(requestData?.detectionTypeData.penaltyFactor) && (
                            <div className="item waypoint neutral-tag">{penalization}</div>
                        )}
                    </div>
                )

                return { zones, panelHeader, disabled: zone.analysisInfo.infringements.length > 1 }
            })

            const collapseItems: CollapseProps['items'] = speedZonesData.map((e, index) => ({
                key: `${index + 1}`,
                label: e.panelHeader,
                children: e.zones,
                showArrow: e.disabled,
            }))

            return <Collapse items={collapseItems} collapsible="icon" accordion={true} bordered={false} />
        }
    })

    if (!zonesInfo.length) {
        return <></>
    }

    return (
        <ParticipantCard number={result.number} onClick={() => onClick(result)} className={className}>
            <div className=" w-full flex-1 text-right">{zonesInfo}</div>
            <PositionsLost positions={positions} />
        </ParticipantCard>
    )
}

export default SpeedCard

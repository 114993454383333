import { byNumber } from '../../sortingfunctions'

export const sortByDistance =(results: MatchTrackResult[] | WaypointsResult[], ascending: boolean = true) => {
    const sortedResults = results.sort((a, b) =>
        byDistance(
            { number: a.number, trackedRelative: a.data.trackedRelative },
            { number: b.number, trackedRelative: b.data.trackedRelative }
        )
    )

    return ascending ? sortedResults : sortedResults.reverse()
}

export function byDistance(sortingDistanceA: SortingDistance, sortingDistanceB: SortingDistance) {
    if (sortingDistanceA.trackedRelative !== sortingDistanceB.trackedRelative) {
        return sortingDistanceB.trackedRelative > sortingDistanceA.trackedRelative ? 1 : -1
    } else {
        return byNumber(sortingDistanceA.number, sortingDistanceB.number)
    }
}

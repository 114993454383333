import Analyzer from '../analyzer'

export default class GenericSpeedAnalyzer extends Analyzer {
    genericSpeedResult: any[]

    constructor(rallyId: number, section: Section, excluded: any, connections: Devices, routesReports: any) {
        super(rallyId, section, excluded, connections, routesReports)
        this.genericSpeedResult = []
    }

    public createWorkers(participants: Participant[], maxWorkers: number) {
        super.createWorkers(participants, maxWorkers, 'generic-speed')
    }
    fetchResults = async (
        participants: Participant[],
        data: DataRallyForm,
        token: string,
        trackCaches: TrackCaches
    ) => {
        for (let participant of participants) {
            const caches = trackCaches[participant.id]
            const params = this._getRequestParams(
                this.rallyId,
                this.section,
                participant,
                data,
                token,
                caches,
                this.connections
            )

            const worker = await this._pool.get()
            if (worker == null) {
                break
            }
            worker.onmessage = async (e: MessageEvent<any>) => {
                const messageType: string = e.data.type
                switch (messageType) {
                    case 'cacheUpdate':
                        this.updateCache(e)
                        return
                    case 'error':
                        this.updateError(e)
                        return
                    case 'genericSpeedResult':
                        const generalParamsForReport = e.data.generalParamsForReport
                        const resultParticipant = e.data.participant
                        let resultInfo: any = e.data.result

                        const disconnectedGpsTrack = generalParamsForReport.disconnectedGpsTrack
                        const result: any = {
                            highlightedPoints: resultInfo.highlightedPoints,
                            detectionType: data.detectionType,
                            id: resultParticipant.id,
                            number: resultParticipant.numero,
                            data: resultInfo,
                            sectionId: this.section.section,
                            rally: this.rallyId,
                            token: token,
                            participantTracks: generalParamsForReport.participantTracks || [],
                            connections: generalParamsForReport.connections,
                            areaPoints: resultInfo.areaPoints,
                            customTracks: [],
                            disconnectedGpsTrack: disconnectedGpsTrack,
                        }
                        this.genericSpeedResult = [...this.genericSpeedResult, result]
                        this.onResults(this.genericSpeedResult)
                        break
                    default:
                        return
                }
                this._pool.put(worker)
            }
            worker.postMessage(['genericSpeedProcess', params])
        }

        while (this._pool.activeWorkers.length > 0) {
            await new Promise(r => setTimeout(r, 0))
        }

        this._pool.free()

        console.timeEnd('analysis')
    }

    getResults = () => this.genericSpeedResult

    _getRequestParams = (
        rallyId: number,
        section: Section,
        participant: Participant,
        data: DataRallyForm,
        token: string,
        caches: TrackCache,
        connections: Devices
    ): ParamsAnalyzerGenericSpeed => {
        const reqData: ParamsAnalyzerGenericSpeed = {
            token: token,
            rally: rallyId,
            section: section,
            routesReports: this.routesReports,
            from: data.from,
            to: data.to,
            participantTrackType: data.participantTrackType,
            participantUINumber: participant.numero,
            detectionType: data.detectionType,
            caches: caches,
            connections,
            participant: participant,
            maxSpeed: data.detectionTypeData.maxSpeed.genericSpeed,
            mode: data.detectionTypeData.mode.genericSpeed,
            threshold: data.detectionTypeData?.threshold?.genericSpeed,
            criteria: data.detectionTypeData.criterias,
            entryLatitude: Number(data.detectionTypeData?.entryCoords?.genericSpeed[0]),
            entryLongitude: Number(data.detectionTypeData?.entryCoords?.genericSpeed[1]),
            entryThreshold: Number(data.detectionTypeData?.entryThreshold?.genericSpeed),
            exitLatitude: Number(data.detectionTypeData?.exitCoords?.genericSpeed[0]),
            exitLongitude: Number(data.detectionTypeData?.exitCoords?.genericSpeed[1]),
            exitThreshold: Number(data.detectionTypeData?.exitThreshold?.genericSpeed),
            impulsesUnit: data.detectionTypeData.impulseUnit,
            impulsesValue: data.detectionTypeData.impulsesValue,
            minSeconds: data.detectionTypeData.minSeconds.genericSpeed,
            tolerance: data.detectionTypeData.tolerance.genericSpeed,
        }

        return reqData
    }
}

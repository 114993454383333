import { useState, type Key } from 'react'
import { useSelectedResultEffect } from '../../hooks/useSelectedResult'
import ErrorBoundarySummaryCard from '../../components/ErrorBoundarySummaryCard/error_boundary_summary_card'
import OppositeCard from '../../components/ParticipantCard/OppositeCard/oppositeCard'
import { useResultsEffect } from '../../hooks/useResults'
import { defaultSortings } from '../../constants/sortingConstants'
import HeaderData from '../../components/dataHeader'
import { Footer } from 'antd/es/layout/layout'
import OppositeDownloader from '../../components/ExcelDownloaders/OppositeDownloader'
import { Divider } from 'antd'

interface Props {
    results: OppositeResult[]
    setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>
    setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>
    timezone: string
}
const OppositeView = ({ results, setSpeedGraphic, setRallyMap, timezone }: Props) => {
    const [selectedResult, setSelectedResult] = useState<OppositeResult | null>(null)
    const [filteredResults, setFilteredResults] = useState<OppositeResult[]>(results)

    useSelectedResultEffect(selectedResult, setRallyMap, setSpeedGraphic)

    const sortings = [...defaultSortings]

    const [sorting, setSorting] = useState<(resultA: Results, resultB: Results) => number>(() => sortings[0].sorting)
    console.log(filteredResults)
    const applyFilters = (sortedResults: OppositeResult[]) => {
        setFilteredResults([...sortedResults])
    }
    useResultsEffect(results, sorting, [], applyFilters)
    return (
        <div className="flex h-full flex-col">
            <div className="m-2 ">
                <HeaderData
                    defaultSorting={sortings[0]}
                    sortings={sortings}
                    onChangeSorting={e => setSorting(() => e)}
                    filteredComponentByZone={null}
                    filterComponents={[]}
                ></HeaderData>
            </div>
            <div className="h-full flex-1 overflow-y-auto p-2">
                {filteredResults.map((result: OppositeResult, index: Number) => {
                    const selected = selectedResult !== null && selectedResult.id === result.id
                    return (
                        <div key={index as Key}>
                            <ErrorBoundarySummaryCard number={result.number} key={result.id}>
                                <OppositeCard
                                    className={selected ? 'selected' : ''}
                                    result={result}
                                    onClick={(result: OppositeResult) => setSelectedResult(result)}
                                    timezone={timezone}
                                    updatePointer={() => {}}
                                    participantTracks={result.participantTracks}
                                />
                            </ErrorBoundarySummaryCard>
                        </div>
                    )
                })}
            </div>
            <div className="mx-2 pt-1">
                <Divider className="m-0 bg-neutral-300" />
            </div>
            <Footer className="m-1 rounded-md bg-neutral-100 p-4 text-center ">
                <OppositeDownloader
                    timezone={timezone}
                    info={filteredResults}
                    disabled={false}
                    text="Save report as excel"
                ></OppositeDownloader>
            </Footer>
        </div>
    )
}

export default OppositeView

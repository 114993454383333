import { finishState } from '../../../../analyzer/waypointsAnalyzer/waypointsFunctions'

type Props = {
    dnsDnf: string
}
const DnsDnf = ({ dnsDnf }: Props) => {
    const waypointsReport = () => {
        if (dnsDnf === finishState.DNS) {
            return (
                <div className="mr-2 h-full w-10 bg-red-anube text-center text-xs font-bold text-black">{dnsDnf}</div>
            )
        } else if (dnsDnf === finishState.DNF) {
            return (
                <>
                    <div className="mr-2 h-full w-10 bg-black text-center text-xs font-bold text-red-anube">
                        {dnsDnf}
                    </div>
                </>
            )
        }

        return <></>
    }

    return waypointsReport()
}
export default DnsDnf

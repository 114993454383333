import Analyzer from '../analyzer'

export default class CustomZoneAnalyzer extends Analyzer {
    customZoneResults: CustomZoneResult[]

    constructor(rallyId: number, section: Section, excluded: any, connections: Devices, routesReports: any) {
        super(rallyId, section, excluded, connections, routesReports)
        this.customZoneResults = []
    }
    public createWorkers(participants: Participant[], maxWorkers: number) {
        super.createWorkers(participants, maxWorkers, 'custom')
    }
    fetchResults = async (
        participants: Participant[],
        data: DataRallyForm,
        token: string,
        trackCaches: TrackCaches
    ) => {
        for (let participant of participants) {
            const caches = trackCaches[participant.id]
            const params = this._getRequestParams(
                this.rallyId,
                this.section,
                participant,
                data,
                token,
                caches,
                this.connections
            )
            const worker = await this._pool.get()
            if (worker == null) {
                break
            }

            worker.onmessage = async (e: MessageEvent<MessageAlgorithmCustomZone>) => {
                const messageType: string = e.data.type
                switch (messageType) {
                    case 'cacheUpdate':
                        this.updateCache(e)
                        return
                    case 'error':
                        this.updateError(e)
                        return
                    case 'customZoneResult':
                        const generalParamsForReport = e.data.generalParamsForReport
                        const resultParticipant = e.data.participant
                        let resultInfo: CustomZoneReport = e.data.result
                        const disconnectedGpsTrack = generalParamsForReport.disconnectedGpsTrack
                        const result: CustomZoneResult = {
                            highlightedPoints: resultInfo.highlightedPoints,
                            detectionType: data.detectionType,
                            id: resultParticipant.id,
                            number: resultParticipant.numero,
                            data: resultInfo,
                            sectionId: this.section.section,
                            rally: this.rallyId,
                            token: token,
                            participantTracks: generalParamsForReport.participantTracks || [],
                            connections: generalParamsForReport.connections,
                            areaPoints: resultInfo.areaPoints,
                            customTracks: [],
                            disconnectedGpsTrack: disconnectedGpsTrack,
                        }
                        this.customZoneResults = [...this.customZoneResults, result]
                        this.onResults(this.customZoneResults)
                        break
                    default:
                        return
                }
                this._pool.put(worker)
            }

            worker.postMessage(['customZoneProcess', params])
        }

        while (this._pool.activeWorkers.length > 0) {
            await new Promise(r => setTimeout(r, 0))
        }

        this._pool.free()
    }

    getResults = () => this.customZoneResults

    _getRequestParams = (
        rallyId: number,
        section: Section,
        participant: Participant,
        data: DataRallyForm,
        token: string,
        caches: TrackCache,
        connections: Devices
    ): ParamsAnalyzerCustomZone => {
        const reqData: ParamsAnalyzerCustomZone = {
            token: token,
            rally: rallyId,
            section: section,
            from: data.from,
            routesReports: this.routesReports,
            to: data.to,
            participantTrackType: data.participantTrackType,
            participantUINumber: participant.numero,
            detectionType: data.detectionType,
            caches: caches,
            connections,
            threshold: data.detectionTypeData.threshold,
            participant: participant,
            consecSecs: Number(data.detectionTypeData.consecSecs),
            entryBearing: isNaN(data.detectionTypeData.entryBearing) ? null : data.detectionTypeData.entryBearing,
            entryLatitude: Number(data.detectionTypeData.entryCoords[0]),
            entryLongitude: Number(data.detectionTypeData.entryCoords[1]),
            entryThreshold: Number(data.detectionTypeData.entryThreshold),
            exitBearing: isNaN(data.detectionTypeData.exitBearing) ? null : data.detectionTypeData.exitBearing,
            exitLatitude: Number(data.detectionTypeData.exitCoords[0]),
            exitLongitude: Number(data.detectionTypeData.exitCoords[1]),
            exitThreshold: Number(data.detectionTypeData.exitThreshold),
            lockForm: data.detectionTypeData.lockForm,
            maxSpeed: Number(data.detectionTypeData.maxSpeed),
            minSpeed: Number(data.detectionTypeData.minSpeed),
            minTimeInZone: data.detectionTypeData.minTimeInZone,
            toleranceKmH: Number(data.detectionTypeData.toleranceKmH),
        }

        return reqData
    }
}

export function groupRanges(ranges: RangeBar[]) {
    if (ranges.length === 0) {
        return []
    }

    let currentGroup = ranges[0]
    let groupedRanges = []

    for (let i = 1; i < ranges.length; i++) {
        if (currentGroup.edges) {
            if (
                currentGroup.edges[1] === ranges[i].edges[0] &&
                currentGroup.disabled === currentGroup.disabled &&
                currentGroup.matchedGps === ranges[i].matchedGps
            ) {
                currentGroup.edges[1] = ranges[i].edges[1]
            } else {
                groupedRanges.push(currentGroup)
                currentGroup = ranges[i]
            }
        }
    }

    groupedRanges.push(currentGroup)

    return groupedRanges
}

export function rangeToRelative(range: RangeBar, totalWidth: number) {
    if (range.edges) {
        let width = ((range.edges[1] - range.edges[0]) / totalWidth) * 100
        let start = (range.edges[0] / totalWidth) * 100

        // avoid thin ranges
        if (width < 1) {
            width = 1
        }

        return {
            width,
            start,
            disabled: range.disabled,
            matchedGps: range.matchedGps,
            firstUnfinished: range.firstUnfinished,
            positions: range.positions,
        }
    }
}

import React from 'react'
import ParticipantCard from '../participantCard'
import moment from 'moment'
import 'moment-timezone'
import { timespanToHHMMSS } from '../../../utils'
import { GiPathDistance } from 'react-icons/gi'
import '../summary_card.css'
import { TimeInZoneState } from '../../../constants/timeInZoneEnum'

interface Props {
    result: CustomZoneResult
    updatePointer: (a: number | null, b: number | null) => void
    participantTracks: number[][][]
    timezone: string
    onClick: (a: CustomZoneResult) => void
    className: string
}
const a = ({ result, participantTracks, updatePointer, timezone, onClick, className }: Props) => {
    const laps = result.data.laps.map((lapEntry, i) => {
        const maxSpeed = lapEntry.cardSpeedPoint === null ? null : lapEntry.cardSpeedPoint[3]
        let entryTime = !lapEntry.entry.approachTimeUtc ? lapEntry.entry.timeUtc : lapEntry.entry.approachTimeUtc
        let exitTime = !lapEntry.exit.approachTimeUtc ? lapEntry.exit.timeUtc : lapEntry.exit.approachTimeUtc
        const entryTimeUi =
            entryTime === -1 ? (
                <span>{' --:--:-- '}</span>
            ) : (
                <span
                    className="clickable min-w-[60px]"
                    onClick={() => {
                        const participantTrackIndex = result.data.participantTrack === 'primary' ? 0 : 1
                        const participantTrack = participantTracks[participantTrackIndex]
                        const point = participantTrack[lapEntry.entry.index]
                        updatePointer(point[0], point[1])
                    }}
                >
                    {' ' +
                        moment(entryTime * 1000)
                            .tz(timezone)
                            .format('HH:mm:ss') +
                        ' '}
                </span>
            )
        const exitTimeUi =
            lapEntry.exit.timeUtc === -1 ? (
                <span className='min-w-[60px]'>{' --:--:-- '}</span>
            ) : (
                <span
                    className="clickable"
                    onClick={() => {
                        const participantTrackIndex = result.data.participantTrack === 'primary' ? 0 : 1
                        const participantTrack = participantTracks[participantTrackIndex]
                        const point = participantTrack[lapEntry.exit.index]
                        updatePointer(point[0], point[1])
                    }}
                >
                    {' ' +
                        moment(lapEntry.exit.timeUtc * 1000)
                            .tz(timezone)
                            .format('HH:mm:ss') +
                        ' '}
                </span>
            )
        const totalTime = entryTime === -1 || exitTime === -1 ? -1 : lapEntry.exit.timeUtc - entryTime
        const totalTimeUi = totalTime === -1 ? '--:--:--' : timespanToHHMMSS(totalTime)
        const lowTotalTimeUiClass = lapEntry.minTimeInZone === TimeInZoneState.INTIME ? '' : 'under-mintimeinzone'
        const maxSpeedColorTag = lapEntry.speedInfo.fastestRange === null ? 'ok-tag' : 'error-tag'
        const maxSpeedDiv =
            maxSpeed === null ? (
                ''
            ) : (
                <div
                    className={`item waypoint ${maxSpeedColorTag} duration-tag clickable`}
                    onClick={() => {
                        if (lapEntry.cardSpeedPoint) {
                            updatePointer(lapEntry.cardSpeedPoint[0], lapEntry.cardSpeedPoint[1])
                        }
                    }}
                >
                    {' ' + maxSpeed + ' km/h '}
                </div>
            )
        const minSpeed = lapEntry.cardSlowSpeedPoint === null ? null : lapEntry.cardSlowSpeedPoint[3]
        const minSpeedColorTag = lapEntry.cardSlowSpeedPoint === null ? 'ok-tag' : 'minspeed-tag'
        const minSpeedDiv =
            minSpeed === null ? (
                ''
            ) : (
                <div
                    className={`item waypoint ${minSpeedColorTag} duration-tag clickable`}
                    onClick={() => {
                        if (lapEntry.cardSlowSpeedPoint) {
                            updatePointer(lapEntry.cardSlowSpeedPoint[0], lapEntry.cardSlowSpeedPoint[1])
                        }
                    }}
                >
                    {' ' + minSpeed + ' km/h '}
                </div>
            )
        const averageSpeedDiv =
            lapEntry.participantAverageSpeed === null ? (
                ''
            ) : (
                <div className="item waypoint neutral-tag duration-tag">
                    {' ' + lapEntry.participantAverageSpeed + ' km/h '}
                </div>
            )

        return (
            <div style={{ position: 'relative', height: '25px' }}>
                {entryTimeUi}
                {exitTimeUi}
                <div className={`time-spent ${lowTotalTimeUiClass}`}>
                    <span>{' ' + totalTimeUi + ' '}</span>
                </div>
                {maxSpeedDiv}
                {minSpeedDiv}
                {averageSpeedDiv}
                <GiPathDistance
                    style={{}}
                    onClick={() => {
                        const coords = []
                        if (participantTracks[0]) {
                            const entryIndex = participantTracks[0].findIndex(e => e[2] === entryTime)
                            const exitIndex = participantTracks[0].findIndex(e => e[2] === exitTime)
                            for (let index = entryIndex; index <= exitIndex; index++) {
                                const point = participantTracks[0][index]
                                if (point) {
                                    coords.push([point[0], point[1], '0'])
                                }
                            }
                            if (entryIndex && exitIndex) {
                                var kmlString =
                                    '<Placemark>' +
                                    '<Style>' +
                                    '<LineStyle>' +
                                    '<color>AA0000ff</color>' +
                                    '  <width>10</width>' +
                                    ' </LineStyle>' +
                                    '</Style>' +
                                    '<MultiGeometry>' +
                                    '<LineString>' +
                                    '<coordinates>' +
                                    coords.join(' ') +
                                    '</coordinates>' +
                                    '</LineString>' +
                                    '</MultiGeometry>' +
                                    '</Placemark>'

                                const kmlDocument = `<?xml version="1.0" encoding="UTF-8"?>
              <kml xmlns="http://www.opengis.net/kml/2.2">
              <Document>
              ${kmlString}
              </Document>
              </kml>`
                                const blob = new Blob([kmlDocument], { type: 'text/xml' })
                                const url = URL.createObjectURL(blob)

                                const link = document.createElement('a')
                                link.href = url
                                link.download = '#' + result.number + '_segment.kml'

                                link.click()

                                URL.revokeObjectURL(url)
                            }
                        }
                    }}
                />
            </div>
        )
    })

    if (!laps.length) {
        return <></>
    }

    return (
        <ParticipantCard number={result.number} onClick={() => onClick(result)} className={className}>
            <div className="flex-1 p-2 text-right">{laps}</div>
        </ParticipantCard>
    )
}

export default a

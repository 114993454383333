import { WorkerPool } from './workerPool'

export default class Analyzer {
    rallyId: number
    routesReports: any
    excluded: any
    section: Section
    connections: Devices
    errors: AnalyzerResult[]
    results: Results[]
    public onErrors: (errorResults: AnalyzerResult[]) => void
    public onResultError: () => void
    public onExcludedWaypointIndexes: (onExcludedWaypointIndexes: any) => void
    public onExcludedWaypointRanges: (excludedWaypointRanges: any) => void
    _pool: WorkerPool
    public onCacheUpdate: (participantId: number, newCache: TrackCache) => void
    public onResults: (results: Results[]) => void
    constructor(rallyId: number, section: Section, excluded: any, connections: Devices, routesReports: any) {
        this.rallyId = rallyId
        this.routesReports = routesReports
        this.excluded = excluded
        this.section = section
        this.connections = connections
        this.errors = []
        this.results = []
        this.onErrors = () => {}
        this.onResultError = () => {}
        this.onExcludedWaypointIndexes = () => {}
        this.onExcludedWaypointRanges = () => {}
        this.onCacheUpdate = () => {}
        this.onResults = () => {}
        this._pool = new WorkerPool(0, '')
    }

    public createWorkers(participants: Participant[], maxWorkers: number, text: string) {
        maxWorkers = Math.min(maxWorkers, participants.length)
        this._pool = new WorkerPool(maxWorkers, text)
    }
    getResults() {
        return this.results
    }
    async updateCache(e: MessageEvent<any>) {
        const participantId = e.data.participantId
        const newParticipantCache = e.data.caches
        this.onCacheUpdate(participantId, newParticipantCache)
    }
    async updateError(e: MessageEvent<any>) {
        this.onResultError()
        this.cancelDownload()
        const participantId = e.data.participantId
        const participantNumber = e.data.participantNumber
        const data = e.data.data
        const result: AnalyzerResult = {
            detectionType: data.detectionType,
            id: participantId,
            number: participantNumber,
        }

        this.errors = [...this.errors, result]
        this.onErrors(this.errors)
    }

    public cancelDownload() {
        this._pool.free()
    }

    getErrors() {
        return this.errors
    }
}

import { Form, Input, InputNumber, Tooltip } from 'antd'
import CustomTimePicker from '../../CustomPickerTime'
import dayjs from 'dayjs'
import CustomInputCoordinates from '../../CustomInputCoordinates'
import type { Rule } from 'antd/es/form'
import { CoordinatesRules } from '../../../constants/coordinatesRules'

interface CustomZoneForm {
    entryCoords: string | undefined
    exitCoords: string | undefined
}

/**
 * Different components that need the custom zone to work
 * @param {CustomZoneForm} Object Object to construct the component
 * @returns
 */
const BaseSpecificCustomZoneForm = ({ entryCoords, exitCoords }: CustomZoneForm) => {
    const defaultMinTimeInZone = dayjs('00:00:00', 'HH:mm:ss')
    const minSeconds = 1
    const tolerance = 0
    const exitThreshold = 25
    const entryThreshold = 25

    return (
        <>
            <Form.Item
                name="entryCoords"
                className="m-0 w-full"
                label={
                    <div>
                        <Tooltip title="Coordinates of the entry point">Entry coords</Tooltip>
                    </div>
                }
                initialValue={entryCoords}
                rules={CoordinatesRules}
            >
                <CustomInputCoordinates defaultValue={entryCoords} />
            </Form.Item>
            <Form.Item
                name="entryThreshold"
                className="m-0 w-full"
                initialValue={entryThreshold}
                label={
                    <div>
                        <Tooltip title="Radius of the maximum radius that the participant can have to get the entry point">
                            Entry radius
                        </Tooltip>
                    </div>
                }
            >
                <InputNumber className=" w-full" precision={0} step={1} min={0} defaultValue={entryThreshold} />
            </Form.Item>
            <Form.Item
                name="entryBearing"
                className="m-0 w-full"
                label={
                    <div>
                        <Tooltip title="Bearing of entry to validate the entry point">Entry bearing</Tooltip>
                    </div>
                }
            >
                <InputNumber className=" w-full" precision={0} step={1} min={0} max={360} />
            </Form.Item>
            <Form.Item
                name="exitCoords"
                className="m-0 w-full"
                label={
                    <div>
                        <Tooltip title="Coordinates of the exit point">Exit coords</Tooltip>
                    </div>
                }
                initialValue={exitCoords}
                rules={CoordinatesRules}
            >
                <CustomInputCoordinates defaultValue={exitCoords} />
            </Form.Item>
            <Form.Item
                name="exitThreshold"
                className="m-0 w-full"
                initialValue={exitThreshold}
                label={
                    <div>
                        <Tooltip title="Radius of the maximum radius that the participant can have to get the exit point">
                            Exit radius
                        </Tooltip>
                    </div>
                }
            >
                <InputNumber className=" w-full" precision={0} step={1} min={0} defaultValue={exitThreshold} />
            </Form.Item>
            <Form.Item
                name="exitBearing"
                className="m-0 w-full"
                label={
                    <div>
                        <Tooltip title="Bearing of entry to validate the exit point">Exit bearing</Tooltip>
                    </div>
                }
            >
                <InputNumber className=" w-full" precision={0} step={1} min={0} max={360} />
            </Form.Item>
            <Form.Item
                name="maxSpeed"
                className="m-0 w-full"
                label={
                    <div>
                        <Tooltip title="Maximum speed that the participant can take inside the points">
                            Max speed
                        </Tooltip>
                    </div>
                }
                initialValue={null}
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value !== null && getFieldValue('minSpeed') !== null) {
                                return Promise.reject(new Error('Only one of Max Speed or Min Speed can be filled.'))
                            }
                            return Promise.resolve()
                        },
                    }),
                ]}
            >
                <InputNumber className=" w-full" precision={0} step={1} min={0} />
            </Form.Item>
            <Form.Item
                name="minSpeed"
                className="m-0 w-full"
                label={
                    <div>
                        <Tooltip title="Minimum speed that the participant can take inside the points">
                            Min speed
                        </Tooltip>
                    </div>
                }
                initialValue={null}
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value !== null && getFieldValue('maxSpeed') !== null) {
                                return Promise.reject(new Error('Only one of Max Speed or Min Speed can be filled.'))
                            }
                            return Promise.resolve()
                        },
                    }),
                ]}
            >
                <InputNumber className=" w-full" precision={0} step={1} min={0} />
            </Form.Item>
            <Form.Item
                name="consecSecs"
                className="m-0 w-full"
                initialValue={minSeconds}
                label={
                    <>
                        <Tooltip title="Minimum number of seconds you must exceed the speed limit continuously">
                            Min sec
                        </Tooltip>
                    </>
                }
            >
                <InputNumber className=" w-full" precision={0} step={1} min={1} defaultValue={minSeconds} />
            </Form.Item>
            <Form.Item
                name="toleranceKmH"
                className="m-0 w-full"
                initialValue={tolerance}
                label={
                    <>
                        <Tooltip title="Tolerance in kilometers per hour allowed in order not to be penalized by the speed limit">
                            Tolerance
                        </Tooltip>
                    </>
                }
            >
                <InputNumber className=" w-full" precision={0} step={1} min={0} defaultValue={tolerance} />
            </Form.Item>
            <Form.Item
                name="minTimeInZone"
                className="m-0 w-full"
                label={
                    <>
                        <Tooltip title="Minimum amount of time they need to be within these two points">
                            Min Time
                        </Tooltip>
                    </>
                }
                initialValue={0}
            >
                <CustomTimePicker defaultMinTimeInZone={defaultMinTimeInZone} />
            </Form.Item>
        </>
    )
}
export default BaseSpecificCustomZoneForm

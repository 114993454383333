import { useState, type Key } from 'react'
import { useSelectedResultEffect } from '../../hooks/useSelectedResult'
import ErrorBoundarySummaryCard from '../../components/ErrorBoundarySummaryCard/error_boundary_summary_card'
import { useResultsEffect } from '../../hooks/useResults'
import { defaultSortings } from '../../constants/sortingConstants'
import HeaderData from '../../components/dataHeader'
import GenericSpeedCard from '../../components/ParticipantCard/GenenicSpeedCard/genericSpeedCard'

interface Props {
    results: GenericSpeedResult[]
    setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>
    setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>
    timezone: string
    onClick: (lon: number, lat: number) => void
}
const GenerciSpeedView = ({ results, setSpeedGraphic, setRallyMap, timezone, onClick }: Props) => {
    const [selectedResult, setSelectedResult] = useState<any | null>(null)
    const [filteredResults, setFilteredResults] = useState<any[]>(results)
    const [infringements, setInfrigments] = useState<boolean>(true)

    useSelectedResultEffect(selectedResult, setRallyMap, setSpeedGraphic)
    const sortings = [...defaultSortings]

    const changeInfrigmentsCheckbox = (event: any) => {
        setInfrigments(event.target.checked)

    }
    const filteredComponentsByModes = [
        {
            name: 'Show infrigments mode',
            checked: infringements,
            function: changeInfrigmentsCheckbox,
        },
    ]

    const [sorting, setSorting] = useState<(resultA: Results, resultB: Results) => number>(() => sortings[0].sorting)
    const infrigmentsFilter = (result: GenericSpeedResult) => {
        if (result.data.genericSpeed.failed){

            return result
        }
        return null
    }
    const filterApply = (result: GenericSpeedResult) => {
        const newResult = Object.assign({}, result)
        newResult.data = Object.assign({}, result.data)
        if (infringements) {
           const filteredInfringements = infrigmentsFilter(newResult)
           return filteredInfringements
        }
        return newResult
    }

    const applyFilters = (sortedResults: GenericSpeedResult[]) => {
        let shownResults = sortedResults.map(e => filterApply(e)).filter(e => e !== null)
        setFilteredResults(shownResults)
    }

    useResultsEffect(results, sorting, [infringements], applyFilters)
    return (
        <div className="flex h-full flex-col">
            <div className="m-2 ">
                <HeaderData
                    defaultSorting={sortings[0].sorting}
                    sortings={sortings}
                    onChangeSorting={e => setSorting(() => e)}
                    filteredComponentByZone={null}
                    filterComponents={filteredComponentsByModes}
                ></HeaderData>
            </div>
            <div className="h-full flex-1 overflow-y-auto p-2">
                {filteredResults.map((result: any, index: Number) => {
                    const selected = selectedResult !== null && selectedResult.id === result.id
                    return (
                        <div key={index as Key}>
                            <ErrorBoundarySummaryCard number={result.number} key={result.id}>
                                <GenericSpeedCard
                                    className={selected ? 'selected' : ''}
                                    result={result}
                                    onClick={(result: GenericSpeedResult) => setSelectedResult(result)}
                                    number={result.number}
                                    updatePointer={onClick}
                                    timezone={timezone}
                                ></GenericSpeedCard>
                            </ErrorBoundarySummaryCard>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default GenerciSpeedView

import { Popover } from 'antd'

type Props = {
    positions: number | undefined
}
const PositionsLost = ({ positions }: Props) => {
    if (!positions && positions === 0) {
        return <></>
    }

    return (
        <Popover
            content={<span>Ha perdido {Math.floor(positions as number)} posiciones GPS</span>}
            trigger={'hover'}
            className="rangebarPositions"
        >
            <div className="mr-2 h-full w-10 self-center bg-[#ff8000ff] text-center text-xs font-bold text-black">
                {Math.floor(positions as number)}
            </div>
        </Popover>
    )
}
export default PositionsLost

import { TimePicker } from 'antd'
import type dayjs from 'dayjs'
import { useEffect, useState } from 'react'

/**
 * @param defaultMinTimeInZone Default minTimeInZone
 * @param x Remaining props that take the component from the parent component
 */
interface PropsTimePicker {
    defaultMinTimeInZone: dayjs.Dayjs
    [x: string]: any
}
/**
 * Custom time pickcer that works in seconds
 * @param {PropsTimePicker} object Objecto to contruct the component
 * @returns
 */
const CustomTimePicker = ({ defaultMinTimeInZone, ...restProps }: PropsTimePicker) => {
    const [time, setTime] = useState(defaultMinTimeInZone)
    const handleTimeChange = (time: dayjs.Dayjs) => {
        if (time) {
            const seconds = time.hour() * 3600 + time.minute() * 60 + time.second()
            restProps.onChange(seconds)
        }
        setTime(time)
    }

    useEffect(() => {
        if (time) {
            const seconds = time.hour() * 3600 + time.minute() * 60 + time.second()
            restProps.onChange(seconds)
        }
    }, [])
    return (
        <TimePicker
            needConfirm={false}
            className="w-full"
            defaultValue={defaultMinTimeInZone}
            value={time}
            size="small"
            onChange={handleTimeChange}
            format="HH:mm:ss"
        />
    )
}
export default CustomTimePicker

import { Card, Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { forwardRef, useContext, useEffect, useImperativeHandle } from 'react'
import { BaseFormContext } from '../../contexts/baseFormContext'

interface Props {
    children: JSX.Element
}

/**
 * Motor for the specific form
 */
const SpecificForm = forwardRef(({ children }: Props, ref) => {
    const [specificForm] = useForm()
    const setSpecificData = useContext(BaseFormContext)
    useImperativeHandle(ref, () => ({
        validate: async () => {
            try {
                await specificForm.validateFields()
                return true
            } catch (error) {
                return false
            }
        },
    }))

    useEffect(() => {
        if (specificForm) setSpecificData(specificForm.getFieldsValue())
    }, [specificForm, children])

    const handleFormValuesChange = (changedValues: any, allValues: any) => {
        setSpecificData(allValues)
    }

    return (
        <Card bodyStyle={{ padding: 2 }} className="mt-1 w-full border-solid border-neutral-300  p-2 bg-neutral-100">
            <Form.Item className=" w-full">
                <Form
                    size="small"
                    form={specificForm}
                    onValuesChange={handleFormValuesChange}
                    className="w-full space-y-1"
                >
                    {children}
                </Form>
            </Form.Item>
        </Card>
    )
})

export default SpecificForm

import { Popover } from 'antd'

type Props = {
    waypointsFailedCount: number
}
const WaypointsFailed = ({ waypointsFailedCount }: Props) => {
    const waypointsReport = () => {
        if (waypointsFailedCount !== 0) {
            return (
                <>
                    <Popover
                        content={<span>Has failed {waypointsFailedCount} waypoints</span>}
                        trigger={'hover'}
                        className="rangebarPositions"
                    >
                        <div className="mr-2 h-full w-10 self-center bg-[red] text-center text-xs font-bold ">
                            {waypointsFailedCount}
                        </div>
                    </Popover>
                </>
            )
        }

        return <></>
    }

    return waypointsReport()
}
export default WaypointsFailed

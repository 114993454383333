import axios from 'axios'

import * as turf from '@turf/turf'
import { getFirstIndexInArea, getLastIndexInArea, mergeArraysKeepingCommon } from '../../outtrack/common'

export function processTracksByWaypoints(
    waypoints: Waypoint[],
    compareTracks: number[][][],
    gpsCheckedWaypoints: WaypointCheck[],
    radius: number,
    firstWp: WaypointAPI,
    lastWp: WaypointAPI,
    minTimeInZone: number
) {
    const unmatchedWaypointIndexes: UnmatchedWaypointIndex[] = compareTracks.map(track =>
        matchPathsByIntersectingWaypoints(waypoints, track, radius, firstWp, lastWp, minTimeInZone)
    )
    const unmatchedWaypointIndexesMerged: number[] = mergeArraysKeepingCommon(
        unmatchedWaypointIndexes[0].waypoints,
        unmatchedWaypointIndexes[1].waypoints
    )

    const unmatchedWaypointToGpsIndexes = matchPathsByIntersectingWaypointsToGps(
        unmatchedWaypointIndexesMerged,
        gpsCheckedWaypoints,
        waypoints,
    )
    for (let index of unmatchedWaypointIndexesMerged) {
        waypoints[index].matched = false
    }
    for (let index = 0; index < unmatchedWaypointToGpsIndexes.length; index++) {
        const element = unmatchedWaypointToGpsIndexes[index];
        waypoints[element.index].matchedGps = true
        waypoints[element.index].blocked = element.blocked
        
    }
    let firstUnfinished = false
    const newWaypoints = waypoints.map(waypoint => {
        if (unmatchedWaypointIndexes[0].waypointFinded) {
            if (!firstUnfinished) {
                if (waypoint.id === unmatchedWaypointIndexes[0].waypointFinded.wpId) {
                    waypoint.firstUnfinished = true
                    firstUnfinished = true
                    waypoint.unfinished = true
                }
            } else {
                waypoint.unfinished = true
            }
        }
        return waypoint
    })
    const processedWaypoint: ProcessedWaypoint = {
        unmatchedIndexes: unmatchedWaypointIndexes,
        waypoints: newWaypoints,
    }
    return processedWaypoint
}
export async function getWaypoints_ChecksForParticipant(rallyId: number, participantId: number, token: string) {
    return axios({
        method: 'GET',
        url: `https://rest.anube.es/rallyrest//default/api/waypoint_checks/${rallyId}/${participantId}.json`,
        params: {
            token: token,
        },
    })
}
export function matchPathsByIntersectingWaypointsToGps(
    index: number[],
    gpsCheckedWaypoints: WaypointCheck[],
    waypoints: Waypoint[],
) {
    gpsCheckedWaypoints = gpsCheckedWaypoints.filter(
        (element: { ut: number }) => element.ut > 0 
    )
    const matchedIndex = []
    for (let index = 0; index < waypoints.length; index++) {
        const unmatchedGpsAndWaypoints = gpsCheckedWaypoints.find(element => element.wp_id === waypoints[index].id)

        if (unmatchedGpsAndWaypoints) {
            matchedIndex.push({index : index , blocked : unmatchedGpsAndWaypoints.blocked})
        }
    }
    return matchedIndex
}

export function matchPathsByIntersectingWaypoints(
    waypoints: Waypoint[],
    comparePath: number[][],
    radius: number,
    firstWp: WaypointAPI,
    lastWp: WaypointAPI,
    minTimeInZone: number
) {
    if (comparePath.length === 0) {
        return {
            waypoints: waypoints.map((coord, i: number) => i),
            waypointFinded: undefined,
        }
    }
    let waypointsZones: IntersectingWaypoint[] = []
    let firstIndex = getLastIndexInArea(
        firstWp.longitude,
        firstWp.latitude,
        firstWp.validation_radio < 1 ? radius : firstWp.validation_radio,
        comparePath,
        0,
        null
    )
    let participantMinTimeZone: number
    if (firstIndex !== null) {
        if (comparePath[firstIndex]) {
            participantMinTimeZone = comparePath[firstIndex][2] + minTimeInZone
        }
    }

    waypoints.map(waypoint => {
        let index = getFirstIndexInArea(
            waypoint.longitude,
            waypoint.latitude,
            waypoint.validation_radio < 1 ? radius : waypoint.validation_radio,
            comparePath,
            0,
            null
        )
        if (index !== null) {
            if (comparePath[index]) {
                waypointsZones.push({
                    wpId: waypoint.id,
                    utcEntry: comparePath[index][2],
                })
            }
        }
    })

    const compareLineString = turf.lineString(comparePath)

    const addIntersectionWithLine = (point: Waypoint, index: number) => {
        return Object.assign({}, point, {
            index,
            intersection: turf.lineIntersect(
                compareLineString,
                turf.circle(turf.point([point.longitude, point.latitude]), point.validation_radio / 1000)
            ),
        })
    }
    let waypointFinded
    if (minTimeInZone > 0) {
        waypointFinded = waypointsZones.find(e => e.utcEntry > participantMinTimeZone)
    }
    const doNotIntercept = (item: any) => item.intersection.features.length === 0
    return {
        waypoints: waypoints
            .map(addIntersectionWithLine)
            .filter(doNotIntercept)
            .map((item: any) => item.index),
        waypointFinded: waypointFinded,
    }
}

export const finishState = {
    DNF: 'DNF',
    DNS: 'DNS',
    FINISH: 'FINISH',
}

//check the "blocked" param from api and check if the participant start/end
export function checkBlocked(waypoints: WaypointAPI[], gpsCheckedWaypoints: WaypointCheck[]) {
    var result = finishState.FINISH
    const wpFirst: WaypointAPI | undefined = waypoints.find((wp: WaypointAPI) => wp.type === 'DSS')
    const wpFinal: WaypointAPI | undefined = waypoints.filter((wp: WaypointAPI) => wp.type === 'ASS').pop()
    // We can't use this because the ecmascript version
    // let wpFinal = waypoints.findLast((wp: WaypointAPI) => wp.type === 'ASS')

    if (wpFirst && wpFinal) {
        gpsCheckedWaypoints.forEach((data: WaypointCheck) => {
            if (data.blocked) {
                if (wpFirst.id === data.wp_id && !data.ut) {
                    // If the participant didn't start and blocked is 1 'DNS' do not start
                    result = finishState.DNS
                } else if (wpFinal.id === data.wp_id && !data.ut) {
                    // If the participant didn't end and blocked is 1 'DNF' do not finish
                    result = finishState.DNF
                }
            }
        })
    }
    return result
}
